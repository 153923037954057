import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router'; // LegacyCompat 변환 X

import {
  CATALOG_ITEM_LIST_URL,
  PARTNER_PLUS_INTRO_URL,
  PARTNER_PLUS_REAL_TIME_URL,
  PRODUCT_REVIEW_LIST_URL,
  STATS_URL,
} from 'rui/components/URL';

export function ScrollToTop(): null {
  const { pathname } = useLocation();
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    // 첫 마운트 이후에만 실행
    if (isMountedRef.current) {
      const shouldScrollToTop = ![
        '/shipping_penalty',
        '/shop_score',
        CATALOG_ITEM_LIST_URL,
        PRODUCT_REVIEW_LIST_URL,
        STATS_URL,
        PARTNER_PLUS_REAL_TIME_URL,
        PARTNER_PLUS_INTRO_URL,
      ].some((path) => pathname.includes(path));

      if (shouldScrollToTop) {
        window.scrollTo({ top: 0 });
      }
    }
    isMountedRef.current = true;
  }, [pathname]);

  return null;
}
