import { IconMegaphone, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetImportantSellerNoticeListQuery } from './hooks/useGetImportantSellerNoticeListQuery';
import { NoticeRoller } from './NoticeRoller';
import { SellerNoticeDisplayTarget, SellerNoticeCategory } from '@/api';
import { getNoticeCountryCodeList } from '@/components/notice/utils';
import { useInfo } from '@/hooks/useInfo';
import { ShopType } from '@/types/info';
import { getUniqSiteIdList } from '@/utils/siteCountry';

const NoticeRollingBanner = () => {
  const { info, is_zigzag } = useInfo();
  const { category_list, display_target_list, site_id_list, country_code_list } = useMemo(() => {
    const is_brand = info.brand_status === 'NORMAL';
    const display_target_list = [SellerNoticeDisplayTarget.ALL];
    const site_country_list = (info.is_parent ? info.child_shop_site_country_list : info.site_country_list) ?? [];

    const site_id_list = getUniqSiteIdList<string>(site_country_list);
    const country_code_list = getNoticeCountryCodeList(site_country_list);

    if (is_zigzag) {
      is_brand
        ? display_target_list.push(SellerNoticeDisplayTarget.BRAND)
        : display_target_list.push(SellerNoticeDisplayTarget.SOHO);
    }
    const is_zpay_shop = info.type === ShopType.ZPAY;
    const category_list = is_zpay_shop
      ? [SellerNoticeCategory.NORMAL, SellerNoticeCategory.ZPAY]
      : [SellerNoticeCategory.NORMAL];

    return { category_list, display_target_list, site_id_list, country_code_list };
  }, [info]);

  const { data } = useGetImportantSellerNoticeListQuery({
    category_list,
    display_target_list,
    site_id_list,
    country_code_list,
  });

  const [active_index, setActiveIndex] = useState(0);
  const [is_hovering, setHovering] = useState(false);
  const item_list = data?.seller_notice_list.item_list ?? [];
  const has_notices = item_list.length > 0;

  useEffect(() => {
    if (!has_notices) return;
    if (is_hovering) return;
    let timer_id;
    const update = () => {
      setActiveIndex((prev) => (prev + 1) % item_list.length);
      timer_id = setTimeout(update, 5000);
    };
    timer_id = setTimeout(update, 1800);
    return () => {
      clearTimeout(timer_id);
    };
  }, [data, is_hovering]);
  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  if (!has_notices) {
    return null;
  }

  return (
    <NoticeBannerWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <IconMegaphone color={semantic_colors.content.primary_inverse} size={16} />
      <NoticeRoller current_notice={item_list[active_index]} />
    </NoticeBannerWrapper>
  );
};

const NoticeBannerWrapper = styled.div`
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export default NoticeRollingBanner;
