import { Divider, HStack, HStackProps } from '@croquiscom/pds';
import React, { PropsWithChildren } from 'react';

export interface GuideButtonsHStackProps extends HStackProps, PropsWithChildren {}

export const GuideButtonsHStack: React.FC<GuideButtonsHStackProps> = ({ children, ...props }) => {
  return (
    <HStack spacing={8} alignment={'center'} {...props}>
      {React.Children.map(children, (child, index) => (
        <>
          {child}
          {index < React.Children.count(children) - 1 && <Divider direction='vertical' length='50%' />}
        </>
      ))}
    </HStack>
  );
};
