import { semantic_colors } from '@croquiscom/pds';
import React from 'react';
import HelperText from './HelperText';

const DataNotReadyMessage: React.FC = () => {
  return (
    <HelperText
      color={semantic_colors.content.tertiary}
      content='노출 확대 성과 데이터를 수집 중입니다.'
      text='성과 준비 중'
    />
  );
};

export default DataNotReadyMessage;
