import { useState } from 'react';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useGetPartnerPlusGnbTooltipText } from '@/components/partner_plus/hooks/useGetPartnerPlusGnbTooltipText';
import { Config } from '@/config';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';

export function useGetPartnerPlusTooltip(enabled?: boolean) {
  const TOOLTIP_KEYS = `PartnerPlusGnb.${Config.shop_main_domain}.Tooltip`;
  const { is_zigzag: is_zigzag_site } = useInfo();
  const { is_zigzag } = useAccount();
  const [tooltip_id, setTooltipId] = useState(localStorage.getItem(TOOLTIP_KEYS));
  const is_shown = is_zigzag || is_zigzag_site;
  const { is_partner_plus } = useGetPlan();
  const { data, isLoading } = useGetPartnerPlusGnbTooltipText(enabled && is_partner_plus && is_shown);
  const is_show = Boolean(is_partner_plus) && is_shown && !isLoading && !!data && tooltip_id !== data?.id;

  return {
    text: data?.contents,
    is_show,
    hideTootip() {
      if (data?.id) {
        setTooltipId(data.id);
        localStorage.setItem(TOOLTIP_KEYS, data.id);
      }
    },
  };
}
