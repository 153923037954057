import './vendor';
import 'rui/scripts/sentry.ts';
import 'regenerator-runtime/runtime.js';
import '@/i18n';
import '../styles/main.css';

import { GlobalStyle as PDSGlobalStyle } from '@croquiscom/pds';
import { Global, css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { Provider as JotaiProvider } from 'jotai';
import { queryClientAtom } from 'jotai/query';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { BrowserRouter as Router } from 'react-router-dom'; // LegacyCompat 변환 X
import AppChecker from './components/AppChecker';
import LoadingWithBox from './components/common/LoadingWithBox';
import { PageViewLogger } from './components/PageViewLogger';
import { Routes } from './components/routes/Routes';
import { ScrollToTop } from './components/ScrollToTop';
import { ShopDomainProvider } from './components/ShopDomainProvider';

import ErrorPage from '@/pages/error';
import { PartnersBridge } from '@/utils/app_utils';
import { isApp } from '@/utils/app_utils/conditions';
import { initVConsole } from '@/utils/app_utils/initVConsole';
import { handleCatchedErrorDefault } from '@/utils/axios';

if (!!process.env.API_MOCK && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('@/mocks/browser');
  worker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e: Error) => {
        handleCatchedErrorDefault(e);
      },
    },
    mutations: {
      onError: (e: Error) => {
        handleCatchedErrorDefault(e);
      },
    },
  },
});

function App(): React.ReactElement | null {
  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider initialValues={[[queryClientAtom, queryClient]]}>
        <Router>
          <PDSGlobalStyle />
          <Global
            styles={css`
              a {
                text-decoration: none;
              }
            `}
          />
          <ScrollToTop />
          <ShopDomainProvider>
            <Suspense fallback={<LoadingWithBox />}>
              <PageViewLogger />
              {isApp() && <AppChecker />}
              <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                <Routes />
              </Sentry.ErrorBoundary>
            </Suspense>
          </ShopDomainProvider>
        </Router>
      </JotaiProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

if (window.location.pathname.startsWith('/cafe24_sync')) {
  const nextUrl = window.location.pathname.replace(/^\/cafe24_sync/, '');
  window.location.href = `/ext/cafe24` + nextUrl + window.location.search;
} else {
  initVConsole();
  PartnersBridge.init().then(() => {
    PartnersBridge.setVisibleBottomNavi(false);
  });
  const root = createRoot(document.getElementById('app')!);
  root.render(<App />);
}
