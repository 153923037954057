import { IconCircleWon } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId, isNotAllowedByShopDepartment } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment, ShopType } from '@/types/info';
import { SellerResource } from 'api';
import { OrderLinkedListUrl } from 'rui/components/URL';

export function createSettlementMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_fulfillment = is_zpay && info.zonly_status === 'NORMAL';
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  const is_site_id_allowed_without_mystore = isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.도매]);
  const is_settlement_allowed =
    is_zpay &&
    is_site_id_allowed &&
    isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]) &&
    checkRes([SellerResource.ZPAY_SETTLEMENT_MANAGEMENT], info.allowed_resource_list);
  // 직잭 메이트(16871)는 연동 관리 항상 노출
  const is_linked_allowed = isAllowedMenuBySiteId(info, [SiteId.카카오톡_쇼핑하기]) || info.id === '16871';
  return [
    {
      id: 'ec_settlement',
      label: t('main_frame::정산 관리'),
      icon: <IconCircleWon size={16} color='currentColor' />,
      items: [
        {
          id: 'ec_settlement_list',
          label: t('main_frame::정산 내역'),
          items: [
            {
              id: 'ec_store_daily_settlement_list',
              label: t('main_frame::일별 스토어 정산'),
              href: '/ec_store_daily_settlement',
              is_allowed: is_settlement_allowed && !info.is_parent && is_site_id_allowed_without_mystore,
            },
            {
              id: 'ec_settlement_list_scheduled',
              label: t('main_frame::정산예정내역'),
              href: '/ec_settlement/scheduled',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
            {
              id: 'ec_settlement_list_based',
              label: t('main_frame::정산기준내역'),
              href: '/ec_settlement/based',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
            {
              id: 'ec_store_all_monthly_settlement_list',
              label: t('main_frame::월별 정산 내역 다운로드'),
              href: '/ec_store_all_monthly_settlement_list',
              is_allowed: is_settlement_allowed && !!info.is_parent,
            },
          ],
        },
        {
          id: 'ec_settlement_detail',
          label: t('main_frame::항목별 정산 상세내역'),
          items: [
            {
              id: 'ec_store_deduction_settlement_list',
              label: t('main_frame::일별 스토어 공제 정산'),
              href: '/ec_store_deduction_settlement',
              is_allowed: is_settlement_allowed && !info.is_parent && is_site_id_allowed_without_mystore,
            },
            {
              id: 'ec_store_rebate_settlement_list',
              label: t('main_frame::일별 스토어 환급 정산'),
              href: '/ec_store_rebate_settlement',
              is_allowed: is_settlement_allowed && !info.is_parent && is_site_id_allowed_without_mystore,
            },
            {
              id: 'ec_store_deduction_acc_list',
              label: t('main_frame::스토어 공제 등록 현황'),
              href: '/ec_store_deduction_acc',
              is_allowed: is_settlement_allowed && !info.is_parent && is_site_id_allowed_without_mystore,
            },
            {
              id: 'ec_store_allotment_amount_settlement',
              label: t('main_frame::스토어 부담 금액 정산 상세'),
              href: '/ec_store_allotment_amount_settlement',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
            {
              id: 'zigzin_store_allotment_amount_settlement',
              label: t('main_frame::직진배송 스토어 부담 금액 정산 상세'),
              href: '/zigzin/store_allotment_amount_settlement',
              is_allowed:
                !info.is_parent &&
                is_fulfillment &&
                checkRes([SellerResource.ZONLY_COUPON_SETTLEMENT], info.allowed_resource_list) &&
                isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
            },
            {
              id: 'ec_fee_settlement',
              label: t('main_frame::스토어 수수료 정산 상세'),
              href: '/ec_fee_settlement',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
          ],
        },
        {
          id: 'ec_settlement_tax',
          label: t('main_frame::부가세/세금계산서 내역'),
          items: [
            {
              id: 'ec_vat_reference_list',
              label: t('main_frame::국내 부가세 참고자료'),
              href: '/ec_vat_reference',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
            {
              id: 'ec_foreign_vat_reference_list',
              label: t('main_frame::해외 부가세 참고자료'),
              href: '/ec_foreign_vat_reference',
              is_allowed: is_settlement_allowed && !info.is_parent,
            },
            {
              id: 'ec_tax_invoice_list',
              label: t('main_frame::세금계산서'),
              href: '/ec_tax_invoice',
              is_allowed: is_settlement_allowed,
            },
          ],
        },
        {
          id: 'ec_order_linked_management',
          label: t('main_frame::연동 정산 관리'),
          is_allowed:
            is_linked_allowed && checkRes([SellerResource.OMS_SETTLEMENT_MANAGEMENT], info.allowed_resource_list),
          items: [
            {
              id: 'order_linked_management_list_ec_settlement',
              label: t('main_frame::연동 정산 관리'),
              href: OrderLinkedListUrl.EC_SETTLEMENT,
            },
            {
              id: 'order_linked_management_list_ec_tax_invoice',
              label: t('main_frame::연동 세금계산서'),
              href: OrderLinkedListUrl.EC_TAX_INVOICE,
            },
            // {
            //   id: 'order_linked_management_list_ec_vat_reference',
            //   label: t('main_frame::연동 국내 부가세 참고자료'),
            //   href: OrderLinkedListUrl.EC_VAT_REFERENCE,
            // },
          ],
        },
      ],
    },
  ];
}
