import { colors, text_styles, IconProps } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';

interface RoundedButtonProps extends HTMLAttributes<HTMLButtonElement> {
  background_color?: string;
  border_color?: string;
  text_color?: string;
  icon?: React.ReactElement<IconProps>;
}

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  background_color = colors.white,
  border_color = colors.gray30,
  text_color = colors.gray800,
  icon,
  children,
  ...props
}) => {
  return (
    <Button
      background_color={background_color}
      border_color={border_color}
      text_color={text_color}
      type='button'
      {...props}
    >
      {icon && React.cloneElement(icon, { color: icon.props.color ?? text_color ?? colors.gray800 })}
      {children}
    </Button>
  );
};

export const Button = styled.button<Omit<RoundedButtonProps, 'icon' | 'text'>>`
  flex: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 16px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  ${text_styles.Body_14_SemiBold}

  &:hover {
    background-color: ${colors.gray30};
  }
  &:active {
    background-color: #e6e6e6;
  }

  ${({ border_color, text_color, background_color }) =>
    `
      border: 1px solid ${border_color};
      color: ${text_color};
      background-color: ${background_color};
    `};
`;
