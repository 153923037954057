import {
  Button,
  IconArrowChevronDown,
  IconLinkExternal,
  Popover,
  colors,
  semantic_colors,
  text_styles,
  useToast,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getUrlByEnvironment } from '@/components/catalog/utils';
import { SiteId } from '@/constants/site';
import { useInfo } from '@/hooks/useInfo';
import { api_createShopDetailShortUrlInAppsflyer } from 'api';

export interface ShopInfoLinkInfoProps {}

export const ShopInfoLinkInfo: React.FC<ShopInfoLinkInfoProps> = () => {
  const { t } = useTranslation('main_frame');
  const { info, is_posty_only, is_zigzag } = useInfo();
  const has_url = !!info?.url && info.url.startsWith('http');

  const toast = useToast();
  const posty_link = info.id ? getUrlByEnvironment(SiteId.포스티, `/stores/${info.id}`) : undefined;

  const handleCopy = (link: string, content: string) => {
    copy(link);
    toast.show({
      kind: 'success',
      content,
      position: 'bottom-center',
    });
  };

  return (
    <>
      <Popover
        arrow={false}
        content={({ close }) => (
          <StyledDropdown>
            {posty_link && is_posty_only ? (
              <StyledDropdownItem
                onClick={() => {
                  handleCopy(posty_link!, t('포스티 스토어홈 링크가 복사되었어요'));
                  close();
                }}
              >
                <StyledText>{t('포스티 스토어홈 링크 복사')}</StyledText>
              </StyledDropdownItem>
            ) : (
              is_zigzag && (
                <StoreHomeLinkItem
                  onClick={(link: string) => {
                    handleCopy(link, t('지그재그 스토어홈 링크가 복사되었어요'));
                    close();
                  }}
                />
              )
            )}
            <StyledDropdownA href='/'>
              <StyledText>다른 입점 스토어 보기</StyledText>
            </StyledDropdownA>
            {has_url && (
              <StyledDropdownA
                href={info?.url}
                target='_blank'
                onClick={() => {
                  close();
                }}
              >
                <StyledText>{t('내 쇼핑몰')}</StyledText>
                <StyledIcon>
                  <IconLinkExternal size={12} color={semantic_colors.content.secondary} />
                </StyledIcon>
              </StyledDropdownA>
            )}
          </StyledDropdown>
        )}
        padding={0}
      >
        <StyledButton kind='secondary' size='xsmall' endIcon={<IconArrowChevronDown />} type='button'>
          {t('내 스토어 바로가기')}
        </StyledButton>
      </Popover>
    </>
  );
};

const StoreHomeLinkItem: React.FC<{ onClick?: (link: string) => void }> = ({ onClick }) => {
  const { t } = useTranslation('main_frame');
  const { data: short_link } = useQuery(
    'createShopDetailShortUrlInAppsflyer',
    async () => {
      const res = await api_createShopDetailShortUrlInAppsflyer();
      return res.data.createShopDetailShortUrlInAppsflyer?.short_url;
    },
    { staleTime: 3600000, cacheTime: 3600000 },
  );

  return (
    <StyledDropdownItem
      onClick={() => {
        onClick?.(short_link!);
      }}
    >
      <StyledText>{t('지그재그 스토어홈 링크 복사')}</StyledText>
    </StyledDropdownItem>
  );
};

const StyledButton = styled(Button)`
  margin: 0 0 16px;
  width: 100%;
`;

const StyledDropdown = styled.div`
  width: 210px;
`;

const StyledDropdownItem = styled.button`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  align-items: center;
  ${text_styles.Body_13_Regular};
  background-color: ${colors.white};
  border: none;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  text-align: left;
  border-radius: 8px;
  text-decoration: none;
  &:visited {
    color: ${semantic_colors.content.primary};
  }
  &:hover,
  &:focus-visible {
    background-color: ${colors.gray10};
  }
  &:active {
    background-color: ${colors.gray20};
  }
`;

const StyledDropdownA = StyledDropdownItem.withComponent('a');

const StyledText = styled.span`
  flex: 1 0 auto;
`;

const StyledIcon = styled.span`
  line-height: 0;
`;
