import { Button, Checkbox, RadioGroup, Textarea, VStack, semantic_colors, text_styles } from '@croquiscom/pds';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightFeedbackScore } from './InsightFeedbackScore';
import useEachPbl from '@/hooks/useEachPbl';
interface FeedbackSurveyProps {
  onSubmit: () => void;
}

export const InsightFeedbackSurvey = ({ onSubmit }: FeedbackSurveyProps) => {
  const { t } = useTranslation();
  const [score, setScore] = useState<number | undefined>();
  const [comment, setComment] = useState<string>();
  const [contact_yn, setContactYn] = useState<boolean>(false);
  const { eachPbl } = useEachPbl();

  const submitSurvey = () => {
    eachPbl({
      navigation: 'bottom_floating',
      category: 'click',
      object_id: 'user_satisfaction_score',
      object_type: 'button',
      extra: JSON.stringify({
        score,
        comment,
        contact_yn,
      }),
    });
    onSubmit();
  };

  const score_items = [1, 2, 3, 4, 5].map((score_value) => ({
    label: <InsightFeedbackScore score={score_value} is_selected={score_value === score}></InsightFeedbackScore>,
    value: score_value,
  }));

  return (
    <VStack mt='24px'>
      <RadioGroup
        onChange={(value) => setScore(value)}
        css={[RadioGroupCustomStyle]}
        radioItemType='box'
        items={score_items}
      ></RadioGroup>
      {score !== undefined && (
        <>
          <VStack mt='16px'>
            <Textarea
              css={[TextareaCustomStyle]}
              placeholder={`(${t('선택사항')}) ${t(
                '선택한 만족도에 대한 소중한 의견을 작성해주시면 서비스 개선에 큰 도움이 됩니다.',
              )}`}
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </VStack>
          <VStack mt='20px'>
            <Checkbox
              css={[`margin-top:20px;`]}
              checked={contact_yn}
              onChange={(e) => setContactYn(e.currentTarget.checked)}
            >
              {t('필요한 경우 연락을 받고 싶습니다.')}
            </Checkbox>
          </VStack>
          <VStack mt='20px'>
            <Button fill kind='primary' onClick={submitSurvey}>
              {t('만족도 보내기')}
            </Button>
          </VStack>
        </>
      )}
    </VStack>
  );
};

const RadioGroupCustomStyle = `
width: 280px;

& > label  {
  width: auto;
  border: none;
  border-radius: 8px;
  &:hover, &:hover +label, & +label {
    border: none;
  }

  &:first-of-type, &:last-of-type {
    border-radius: 8px;
  }
  &:not(:first-of-type) {
    margin: 0;
  }
}
`;

const TextareaCustomStyle = `
padding: 8px 12px;
${text_styles.Body_13_Regular};
border: 1px solid ${semantic_colors.border.primary};
border-radius: 8px;
resize: none;

&::placeholder {
  color: ${semantic_colors.content.tertiary};
}
`;
