import { Alert, Divider, VStack, colors } from '@croquiscom/pds';
import React from 'react';
import { useQueryClient } from 'react-query';
import { PushPreferenceCategory } from '../constants';
import PartnerPlusBanner from './PartnerPlusBanner';
import SettingGroupTitle from './SettingGroupTitle';
import SettingListItem from './SettingListItem';
import {
  GetPartnerCenterAppPushPreferenceListQuery,
  SellerResource,
  useGetPartnerCenterAppPushAgreementQuery,
  useGetPartnerCenterAppPushPreferenceListQuery,
  useUpdatePartnerCenterAppPushPreferenceMutation,
} from '@/api';
import { useGetSubscriptionPriceForPartnerPlus } from '@/components/partner_plus/intro/hooks/useGetSubscriptionPriceForPartnerPlus';
import { useGetZigzinCurrentState } from '@/components/zigzin/sign_up/hooks';
import { useGetPlan } from '@/hooks/useGetPlan';
import { useInfo } from '@/hooks/useInfo';
import { usePbl } from '@/hooks/usePbl';

interface AppPushNotificationDetailSettingProps {
  is_app_push_notification_enabled?: boolean;
}

const AppPushNotificationDetailSetting = ({
  is_app_push_notification_enabled,
}: AppPushNotificationDetailSettingProps) => {
  const { isAllowed } = useInfo();
  const query_client = useQueryClient();
  const { pbl } = usePbl();
  const { is_zigzin } = useGetZigzinCurrentState(true);
  const { data, refetch, isLoading } = useGetPartnerCenterAppPushPreferenceListQuery();
  const { data: partner_plus_data } = useGetSubscriptionPriceForPartnerPlus();
  const { is_partner_plus } = useGetPlan();
  const { data: app_push_agreement_data } = useGetPartnerCenterAppPushAgreementQuery();
  const is_on_notification = Boolean(app_push_agreement_data?.getPartnerCenterAppPushAgreement);
  const { mutateAsync } = useUpdatePartnerCenterAppPushPreferenceMutation({
    onMutate: ({ input: { push_receive_group } }) => {
      query_client.setQueryData(
        ['GetPartnerCenterAppPushPreferenceList'],
        (old: GetPartnerCenterAppPushPreferenceListQuery | undefined): GetPartnerCenterAppPushPreferenceListQuery => ({
          getPartnerCenterAppPushPreferenceList: {
            push_preference_list:
              old?.getPartnerCenterAppPushPreferenceList?.push_preference_list.map((v) =>
                v.push_receive_group === push_receive_group
                  ? {
                      ...v,
                      enabled: !v.enabled,
                    }
                  : v,
              ) || [],
          },
        }),
      );
    },
    onSuccess() {
      refetch();
    },
    onError() {
      Alert({
        kind: 'error',
        title: '알림',
        text: '알림 설정 변경에 실패했습니다.',
      });
    },
  });
  const is_zigzin_available = is_zigzin && isAllowed([SellerResource.ZONLY_MANAGEMENT_READ]);
  const getSwitchOn = (category: PushPreferenceCategory) => {
    return Boolean(
      data?.getPartnerCenterAppPushPreferenceList?.push_preference_list.find(
        (item) => item.push_receive_group === category,
      )?.enabled,
    );
  };

  const handleSwitchClick = async (category: PushPreferenceCategory) => {
    try {
      const push_preference = getSwitchOn(category);
      await mutateAsync({
        input: {
          push_receive_group: category,
          enabled: !push_preference,
        },
      });

      pbl({
        navigation: 'notification_settings',
        object_type: 'button',
        category: 'click',
        object_id: category,
        extra: JSON.stringify({
          is_selected: !push_preference,
        }),
      });
    } catch {
      Alert({
        kind: 'error',
        title: '알림',
        text: '알림 설정 변경에 실패했습니다.',
      });
    }
  };

  return (
    <>
      <VStack p={16} spacing={16}>
        <VStack spacing={16}>
          <SettingGroupTitle
            title='주문'
            disabled={!is_partner_plus || !is_app_push_notification_enabled || !is_on_notification}
            description={
              (partner_plus_data?.grade || 0) <= 7
                ? '월 거래액 700만원 미만 스토어에게만 알림이 제공됩니다.'
                : undefined
            }
          />
          <SettingListItem
            disabled={isLoading || !is_partner_plus || !is_app_push_notification_enabled || !is_on_notification}
            is_partnerplus
            title='스토어배송 주문'
            description='신규 주문 안내 등'
            isSwitchOn={getSwitchOn(PushPreferenceCategory.STORE_ORDER)}
            onSwitchClick={() => handleSwitchClick(PushPreferenceCategory.STORE_ORDER)}
          />
          <SettingListItem
            disabled={isLoading || !is_partner_plus || !is_app_push_notification_enabled || !is_on_notification}
            is_partnerplus
            title='직진배송 주문'
            description='신규 주문 안내 등'
            isSwitchOn={getSwitchOn(PushPreferenceCategory.ZIGZIN_ORDER)}
            onSwitchClick={() => handleSwitchClick(PushPreferenceCategory.ZIGZIN_ORDER)}
          />
          {!is_partner_plus && (
            <PartnerPlusBanner disabled={isLoading || !is_app_push_notification_enabled || !is_on_notification} />
          )}
        </VStack>
        <Divider color={colors.gray20} spacing={8} />
        <VStack spacing={16}>
          <SettingGroupTitle
            title='직진배송'
            disabled={!is_zigzin_available || !is_app_push_notification_enabled || !is_on_notification}
          />
          <SettingListItem
            disabled={isLoading || !is_zigzin_available || !is_app_push_notification_enabled || !is_on_notification}
            title='운영/정산/비용'
            description='물류비 납부, 물류비 차감 정산 등'
            isSwitchOn={getSwitchOn(PushPreferenceCategory.ZIGZIN_GENERAL)}
            onSwitchClick={() => handleSwitchClick(PushPreferenceCategory.ZIGZIN_GENERAL)}
          />
          <SettingListItem
            disabled={isLoading || !is_zigzin_available || !is_app_push_notification_enabled || !is_on_notification}
            title='입고/반출'
            description='입고 신청(택배/화물), 입고 신청 취소 등'
            isSwitchOn={getSwitchOn(PushPreferenceCategory.ZIGZIN_STOCK_AND_CARRY_OUT)}
            onSwitchClick={() => handleSwitchClick(PushPreferenceCategory.ZIGZIN_STOCK_AND_CARRY_OUT)}
          />
        </VStack>
        <Divider color={colors.gray20} spacing={8} />
        <VStack spacing={16}>
          <SettingGroupTitle title='기타' disabled={!is_app_push_notification_enabled || !is_on_notification} />
          <SettingListItem
            disabled={isLoading || !is_app_push_notification_enabled || !is_on_notification}
            title='스토어 관리'
            description='세금계산서, 정보 변경 요청 등'
            isSwitchOn={getSwitchOn(PushPreferenceCategory.GENERAL)}
            onSwitchClick={() => handleSwitchClick(PushPreferenceCategory.GENERAL)}
          />
        </VStack>
      </VStack>
    </>
  );
};

export default AppPushNotificationDetailSetting;
