import { Alert, BaseText, colors, Confirm, HStack, Modal, semantic_colors, text_styles, VStack } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateShopSubscriptionMutation, IssuedCouponFragment } from '@/api';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

dayjs.locale('ko');

interface CouponSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  issued_coupon: IssuedCouponFragment;
  item_id: string;
  product_id: string;
}

const CouponSubscriptionModal: React.FC<CouponSubscriptionModalProps> = ({
  isOpen,
  onClose,
  issued_coupon,
  item_id,
  product_id,
}) => {
  const { t } = useTranslation('partner_plus');
  const shop_prefix = useShopPrefix();
  const history = useHistory();
  const info = useInfo();

  const { mutate } = useCreateShopSubscriptionMutation({
    onSuccess: () => {
      Alert({
        title: t('무료체험이 시작됐습니다.'),
        text: t('구독 관리 페이지에서 무료체험 정보를 확인할 수 있습니다.'),
        zIndex: 2001,
        onConfirm: async () => {
          await info.requestInfo();
          history.push(`${shop_prefix}${PartnerPlusNavUrl.MANAGEMENT_BENEFIT}`);
        },
      });
    },
    onError: (error: { message: string }) => {
      Confirm({
        title: t('무료체험 시작 실패'),
        text: (
          <Trans t={t}>
            {error.message}
            <br />
            문제가 지속된다면 인사이트 상담센터로 문의 부탁드립니다.
          </Trans>
        ),
        zIndex: 2001,
        confirmText: t('인사이트 상담센터 바로가기'),
        cancelText: t('닫기'),
        onConfirm: () => {
          window.open('https://insight-kakaostyle.zendesk.com/hc/ko', '_blank', 'noreferrer');
        },
      });
    },
  });

  const onCouponSubscriptionStart = () => {
    mutate({
      input: {
        item_id,
        product_id,
        applied_affiliate_discount: false,
        issued_coupon_id: issued_coupon.id,
      },
    });
  };

  return (
    <Modal
      opened={isOpen}
      width={500}
      cancelText={t('취소')}
      confirmText={t('시작하기')}
      onCancel={onClose}
      onExited={onClose}
      onConfirm={onCouponSubscriptionStart}
      title={t('무료체험')}
      zIndex={2000}
    >
      <VStack spacing={24} p='16px 24px'>
        <BaseText
          css={css`
            text-align: center;
          `}
          kind='Heading_24_Bold'
        >
          {t('무료체험을 시작하시겠어요?')}
        </BaseText>
        <VStack spacing={16}>
          <CouponContent alignment='center'>
            <BaseText kind='Body_13_SemiBold' color={colors.gray550}>
              {t('쿠폰명')}
            </BaseText>
            <BaseText kind='Body_13_Regular' color={semantic_colors.content.primary}>
              {issued_coupon.name}
            </BaseText>
          </CouponContent>
          <CouponContent alignment='center'>
            <BaseText kind='Body_13_SemiBold' color={colors.gray550}>
              {t('쿠폰 적용 플랜')}
            </BaseText>
            <BaseText kind='Body_13_Regular' color={semantic_colors.content.primary}>
              {issued_coupon.policy.target_key}
            </BaseText>
          </CouponContent>
          <CouponContent alignment='center'>
            <BaseText kind='Body_13_SemiBold' color={colors.gray550}>
              {t('무료체험 기간')}
            </BaseText>
            <BaseText kind='Body_13_Regular' color={semantic_colors.content.primary}>
              {t('{{day}}일', { day: issued_coupon.free_trial_day })}
            </BaseText>
          </CouponContent>
          <CouponNoticeList>
            <li>
              <BaseText kind='Body_14_Regular' color={colors.gray550}>
                <Trans t={t}>
                  무료체험 기간 동안은 구독료가 청구되지 않으며,
                  <br />
                  체험 기간 종료 후에도 자동으로 결제되거나 유료로 전환되지 않습니다.
                </Trans>
              </BaseText>
            </li>
            <li>
              <BaseText kind='Body_14_Regular' color={colors.gray550}>
                <Trans t={t}>
                  무료체험 기간이 끝난 후 서비스를 계속 사용하시려면
                  <br />
                  플랜 안내 페이지에서 정식으로 구독 신청을 진행해주세요.
                </Trans>
              </BaseText>
            </li>
          </CouponNoticeList>
        </VStack>
      </VStack>
    </Modal>
  );
};

const CouponContent = styled(HStack)`
  justify-content: space-between;
`;

const CouponNoticeList = styled.ul`
  list-style: disc;
  ${text_styles.Body_13_Regular};
  color: ${colors.gray550};
  padding: 0 16px;

  & li {
    white-space: nowrap;
  }
`;

export default CouponSubscriptionModal;
