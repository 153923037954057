import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import range from 'lodash/range';
import React from 'react';
import { SegmentButtonGroup } from './unstable/SegmentButtonGroup';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageClick: (number) => void;
}

const DISTANCE_FROM_CURRENT = 6;

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageClick }) => {
  // 현재 페이지 기준, 좌/우에 DISTANCE_FROM_CURRENT 개의 페이지를 보여주기 위한 연산입니다.
  // 좌/우 각각 6개 이상의 페이지가 존재할 경우, 양끝 두개의 페이지를 제거하고 ...을 추가한뒤, 맨앞/맨뒤 페이지를 보여주어
  // 끝으로 이동 및 총 페이지수를 볼수있도록 합니다.
  const pageButtons = (total, current) => {
    const pages = (
      current < DISTANCE_FROM_CURRENT
        ? range(2 * DISTANCE_FROM_CURRENT + 1)
        : current + DISTANCE_FROM_CURRENT > total
          ? range(total - 2 * DISTANCE_FROM_CURRENT, total + 1)
          : range(current - DISTANCE_FROM_CURRENT, current + DISTANCE_FROM_CURRENT + 1)
    ).filter((page) => page <= totalPages && page >= 0);

    // 양끝 페이지를 수정합니다.
    if (current > DISTANCE_FROM_CURRENT) {
      pages.shift();
      pages.shift();
      pages.unshift(-1);
      pages.unshift(1);
      pages.unshift(0);
    }
    if (current < total - DISTANCE_FROM_CURRENT) {
      pages.pop();
      pages.pop();
      pages.push(-1);
      pages.push(total - 1);
      pages.push(total);
    }

    // 렌더하기 용이한 형태로 변환합니다.
    return pages.map((value, i) =>
      value < 0
        ? {
            label: '...',
            value: `${i}th-empty`,
            disabled: true,
          }
        : {
            label: value + 1,
            value,
            disabled: false,
          },
    );
  };

  return (
    <StyleSegmentButtons
      selected={currentPage}
      onChange={(page) => {
        if (typeof page === 'number') {
          onPageClick(page);
        }
      }}
      items={pageButtons(totalPages, currentPage)}
      totalPages={totalPages}
    />
  );
};

const StyleSegmentButtons = styled(SegmentButtonGroup)<{ totalPages: number }>`
  .segment-button {
    margin: 0 4px 0 0;
    border: none;
    width: 26px;
    min-width: ${({ totalPages }) => (totalPages < 1000 ? '26px' : '36px')};
    height: 26px;
    color: ${colors.gray550};
  }
  .segment-button--selected {
    z-index: 1;
    border: solid 1px ${colors.blue500};
    color: ${colors.blue500};
  }
`;

export default Pagination;
