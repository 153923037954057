import React from 'react';

import { Trans } from 'react-i18next';
import TransPlainText from '@/components/common/TransPlainText';
import { MissionType, ProgramCard, ProgramList } from '@/components/seller_support_program/types';
import { CONTENT_REGISTRATION_PATH } from '@/constants/onboarding_funnels/content_registration';
import { PRODUCT_REGISTRATION_PATH } from '@/constants/onboarding_funnels/product_registration';
import { STORE_SETTING_PATH } from '@/constants/onboarding_funnels/store_setting';

export const PROGRAM_KEY = 'PARTNER_PROGRAM_2023';
export const register_programs: ProgramCard[] = [
  {
    program_type: ProgramList.고객_확장_프로그램,
    tag: <TransPlainText ns='seller_support_program' i18nKey='충성고객을 확보하고 싶다면' />,
    name: <TransPlainText ns='seller_support_program' i18nKey='고객 확장 프로그램' />,
    description: <TransPlainText ns='seller_support_program' i18nKey='Z결제 판매수수료 최대 0.5%p 할인' />,
    conditional_notice: (
      <TransPlainText
        ns='seller_support_program'
        i18nKey='빅데이터 기반의 스토어별 고객 분석을 통한 스토어 성장을 위한 맞춤형 마케팅 프로그램'
      />
    ),
    caution_list: [
      { text: <TransPlainText ns='seller_support_program' i18nKey='마케팅 프로그램 지원' /> },
      { text: <TransPlainText ns='seller_support_program' i18nKey='쿠폰 할인 지원금 20% (카카오스타일)' /> },
    ],
    button_group: [
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='신청하기' />,
        type: 'register',
      },
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='상세보기' />,
        type: 'move_detail',
      },
    ],
  },
];

export const SMALL_BIZ_CARDS: ProgramCard[] = [
  {
    mission_type: MissionType.STORE_ONBOARDING,
    tag: <TransPlainText ns='seller_support_program' i18nKey='Mission 1' />,
    name: <TransPlainText ns='seller_support_program' i18nKey='스토어 온보딩' />,
    description: <TransPlainText ns='seller_support_program' i18nKey='Z결제 판매수수료 0.5%p 할인' />,
    conditional_notice: <TransPlainText ns='seller_support_program' i18nKey='모든 온보딩 미션 완료 시' />,
    caution_list: [],
    button_group: [
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 달성하러 가기' />,
        type: 'register',
      },
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 상세 보기' />,
        type: 'move_detail',
      },
    ],
  },
  {
    mission_type: MissionType.COUPON,
    tag: <TransPlainText ns='seller_support_program' i18nKey='Mission 2' />,
    name: <TransPlainText ns='seller_support_program' i18nKey='쿠폰 발급' />,
    description: <TransPlainText ns='seller_support_program' i18nKey='Z결제 판매수수료 1%p 할인' />,
    conditional_notice: (
      <TransPlainText ns='seller_support_program' i18nKey='‘쿠폰 상시 발급 & 추천 스킴 자동 적용’ 발행 유지 시' />
    ),
    caution_list: [],
    button_group: [
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 달성하러 가기' />,
        type: 'register',
      },
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 상세 보기' />,
        type: 'move_detail',
      },
    ],
  },
  {
    mission_type: MissionType.CUSTOMER_EXPANSION,
    tag: <TransPlainText ns='seller_support_program' i18nKey='Mission 3' />,
    name: <TransPlainText ns='seller_support_program' i18nKey='고객 확장 프로그램' />,
    description: <TransPlainText ns='seller_support_program' i18nKey='Z결제 판매수수료 최대 0.5%p 할인' />,
    conditional_notice: (
      <Trans ns='seller_support_progrom'>
        고객 확장 프로그램에 참여 상태 유지 시<br />
        *카카오스타일 쿠폰 할인 지원금 (20%)
      </Trans>
    ),
    caution_list: [],
    button_group: [
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='참여하기' />,
        type: 'register',
      },
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 상세 보기' />,
        type: 'move_detail',
      },
    ],
  },
  {
    mission_type: MissionType.ADDON_SERVICE,
    tag: <TransPlainText ns='seller_support_program' i18nKey='Mission 4' />,
    name: <TransPlainText ns='seller_support_program' i18nKey='부가서비스' />,
    description: <TransPlainText ns='seller_support_program' i18nKey='Z결제 판매수수료 1%p 할인' />,
    conditional_notice: (
      <Trans ns='seller_support_program'>
        광고비 5만원 이상 자동결제 설정
        <br />
        지그재그 광고그룹 1개 이상 상시 ON
      </Trans>
    ),
    caution_list: [],
    button_group: [
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 달성하러 가기' />,
        type: 'register',
      },
      {
        label: <TransPlainText ns='seller_support_program' i18nKey='미션 상세 보기' />,
        type: 'move_detail',
      },
    ],
  },
];

export const TUTORIAL_LINK_ITEMS = {
  PRODUCT_REGISTRATION: PRODUCT_REGISTRATION_PATH,
  CONTENT_REGISTRATION: CONTENT_REGISTRATION_PATH,
  STORE_SETTING: STORE_SETTING_PATH,
};

export enum PROGRAM_STATE {
  APPLIED = 'APPLIED',
  UNAPPLIED = 'UNAPPLIED',
  GRADUATED = 'GRADUATED',
}
export enum MISSION_STATE {
  EXPIRED = 'EXPIRED',
  DROPPED = 'DROPPED',
  SATISFIED = 'SATISFIED',
  UNSATISFIED = 'UNSATISFIED',
}
export enum DISCOUNT_STATE {
  NONE = 'NONE',
  EXPECTED_APPLYING = 'EXPECTED_APPLYING',
  APPLYING = 'APPLYING',
  EXPECTED_EXPIRING = 'EXPECTED_EXPIRING',
  EXPIRED = 'EXPIRED',
}

export enum ProgramParticipationStatus {
  NONE = 'NONE',
  ADMIN_WITHDRAWN = 'ADMIN_WITHDRAWN',
  PARTICIPATION_PENDING = 'PARTICIPATION_PENDING',
  PARTICIPATING_ONLY_STANDBY = 'PARTICIPATING_ONLY_STANDBY',
  PARTICIPATING = 'PARTICIPATING',
  WITHDRAWAL_APPLIED = 'WITHDRAWAL_APPLIED',
  WITHDRAWN = 'WITHDRAWN',
  PAUSED = 'PAUSED',
}

export enum SellerParticipationStatus {
  PARTICIPATING = 'PARTICIPATING',
  PARTICIPATION_EXPECTED = 'PARTICIPATION_EXPECTED',
  WITHDRAWAL_EXPECTED = 'WITHDRAWAL_EXPECTED',
  CLOSED = 'CLOSED',
}
