import * as Sentry from '@sentry/react';
import React from 'react';
import { Route } from 'react-router'; // LegacyCompat 변환 X
import { LoginChecker } from '../LoginChecker';
import { MainFrame } from '../main_frame/MainFrame';
import ErrorPage from '@/pages/error';

export interface ManagementRouteProps {
  path: string;
  exact?: boolean;
  // 개발 편의성을 위해 children으로 Route 사용 방법을 통일했습니다.
  children: React.ReactNode;
  current_menu?: string;
  is_support_mobile?: boolean;
  is_support_app?: boolean;
  is_fullscreen?: boolean;
  hide_accountbar?: boolean;
  inactive_content_scroll?: boolean;
}

export function ManagementRoute(props: ManagementRouteProps): React.ReactElement {
  const { path, exact, children, current_menu, inactive_content_scroll } = props;
  return (
    <Route path={path} exact={exact}>
      <LoginChecker isRequired>
        <MainFrame
          current_menu={current_menu}
          is_support_mobile={props.is_support_mobile}
          is_support_app={props.is_support_app}
          is_fullscreen={props.is_fullscreen}
          inactive_content_scroll={inactive_content_scroll}
        >
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>
        </MainFrame>
      </LoginChecker>
    </Route>
  );
}
