import { Button, Drawer, IconX, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import React, { FC, PropsWithChildren, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { drawer_menu_open_atom } from '@/app/rui/components/main_frame/atom';
import { Config } from '@/config';
import { useAccount } from '@/hooks/useAccount';
import axios from '@/utils/axios';

interface Props extends PropsWithChildren {}

const DrawerMenu: FC<Props> = ({ children }) => {
  const { t } = useTranslation('account');
  const { clear } = useAccount();
  const [opened, onClose] = useAtom(drawer_menu_open_atom);

  const logout = async () => {
    try {
      await axios.request({
        baseURL: `/api/provider/${Config.shop_main_domain}`,
        method: 'POST',
        url: '/logout',
      });
    } catch (error) {
      alert(error);
    }
    clear();
    window.location.href = '/login';
  };

  return (
    <Suspense>
      <Drawer opened={opened} onClose={() => onClose(false)}>
        <DrawerWrap>
          <StyledCloseButton type='button' onClick={() => onClose(false)}>
            <IconX size={24} color={semantic_colors.content.tertiary} />
          </StyledCloseButton>
          {children}
          <VStack mt={-10} p={20} pt={0} spacing={20}>
            <Button kind='secondary' size='small' fill onClick={logout}>
              {t('로그아웃')}
            </Button>
          </VStack>
        </DrawerWrap>
      </Drawer>
    </Suspense>
  );
};

export default DrawerMenu;

const DrawerWrap = styled(VStack)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${colors.gray10};
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-block;
  width: auto;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
`;
