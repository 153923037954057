import { IconLinkExternal, IconNew, colors, semantic_colors, text_styles } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { SubMenuEntry } from './types';
import { ShopLink } from '@/components/route/ShopLink';

export interface SubMenuItemProps {
  entry: SubMenuEntry;
  onClick?: (entry: SubMenuEntry) => void;
  is_selected?: boolean;
}

export const SubMenuItem: React.FC<SubMenuItemProps> = ({ entry, onClick, is_selected }) => {
  const label = (
    <StyledLabel>
      {entry.label}
      {entry.badge}
      {entry.is_new && (
        <StyledNewIcon>
          <IconNew size={8} color={semantic_colors.brand.primary} />
        </StyledNewIcon>
      )}
      {entry.is_external && <IconLinkExternal size={12} color='currentColor' />}
    </StyledLabel>
  );
  const handleClick = useCallback(() => {
    onClick?.(entry);
  }, [entry, onClick]);
  if (entry.href != null && entry.href.startsWith('http')) {
    return (
      <StyledMenuA href={entry.href} target='_blank' onClick={handleClick} is_selected={is_selected}>
        {label}
      </StyledMenuA>
    );
  } else if (entry.href != null) {
    return (
      <StyledMenuLink to={entry.href} onClick={handleClick} is_selected={is_selected}>
        {label}
      </StyledMenuLink>
    );
  }
  return (
    <StyledMenuItem type='button' onClick={handleClick} is_selected={is_selected}>
      {label}
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled('button', { shouldForwardProp: (prop) => prop !== 'is_selected' })<{
  is_selected?: boolean;
}>`
  display: block;
  width: 100%;
  margin: 2px 0;
  padding: 4px 12px;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  transition: 0.1s background-color;
  border-radius: 8px;
  ${text_styles.Body_13_Regular};
  &:visited {
    color: ${semantic_colors.content.primary};
  }
  &:hover,
  &:focus-visible {
    background-color: ${colors.gray20};
  }
  &:active {
    background-color: ${colors.gray30};
  }
  ${({ is_selected }) =>
    is_selected &&
    css`
      color: ${semantic_colors.accent.primary};
      ${text_styles.Body_13_SemiBold};
      background-color: ${colors.blue100};
      &:hover,
      &:focus-visible,
      &:active {
        background-color: ${colors.blue100};
      }
      &:visited {
        color: ${semantic_colors.accent.primary};
      }
    `}
`;

const StyledMenuLink = StyledMenuItem.withComponent(ShopLink);
const StyledMenuA = StyledMenuItem.withComponent('a');

const StyledLabel = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledNewIcon = styled.span`
  display: block;
  margin: 0 0 4px -2px;
  line-height: 0;
`;
