import { IconZDelivery } from '@croquiscom/pds';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopType } from '@/types/info';
import { SellerResource } from 'api';
import { CATALOG_ITEM_DETAIL_NEW_URL, WarehousedInUrl } from 'rui/components/URL';
import { ALPHA_ZIGZIN_SHOP_ID, PRODUCTION_ZIGZIN_SHOP_ID, SHOP_KIDS_CATEGORY_ID } from 'rui/constants';

export function createZigzinMenuTree({ info, zigzin_agreed_date, account }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_fulfillment = is_zpay && info.zonly_status === 'NORMAL';
  const is_main_site_id_allowed = isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]);
  const is_sub_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  const is_fulfillment_new = zigzin_agreed_date?.isBetween(dayjs().subtract(30, 'day'), dayjs());
  const ZIGZAGMATE_PARENT_SHOP_ID = account.is_dev || account.is_alpha ? '20278' : '16871';
  const is_zigzagmate_parent_shop = info.id === ZIGZAGMATE_PARENT_SHOP_ID;
  const is_zigzin_allowed = (!info.is_parent && is_fulfillment) || is_zigzagmate_parent_shop;

  const is_not_fulfillment = is_zpay && info.zonly_status !== 'NORMAL';
  const is_parent = info.is_parent ?? false;
  const is_intro_allowed = info.trait.category !== SHOP_KIDS_CATEGORY_ID && is_not_fulfillment && !is_parent;

  return [
    {
      id: 'zigzin',
      label: t('main_frame::직진배송'),
      icon: <IconZDelivery size={16} color='currentColor' />,
      is_allowed: is_zigzin_allowed || is_intro_allowed,
      is_new: is_fulfillment_new,
      items: [
        {
          id: 'zigzin_intro',
          label: t('main_frame::직진배송 소개'),
          href: '/zigzin/intro',
          is_allowed: is_intro_allowed,
        },
        {
          id: 'zigzin_fee_promotion',
          label: t('main_frame::물류 프로모션'),
          href: '/zigzin/outlet_promotion',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_insight',
          label: t('main_frame::직진배송 인사이트'),
          href: WarehousedInUrl.INSIGHT_RECOMMENDED,
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_INSIGHT], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_add_product',
          label: t('main_frame::상품 등록'),
          href: CATALOG_ITEM_DETAIL_NEW_URL,
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_penalty_item',
          label: t('main_frame::판매 제한 상품 관리'),
          href: '/zigzin/penalty_item',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_warehoused_in_v2_request',
          label: t('main_frame::입고 신청'),
          href: '/zigzin/warehoused_in/v2/request_list',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_warehoused_in_v2',
          label: t('main_frame::입고 현황'),
          href: '/zigzin/warehoused_in/v2',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_stock',
          label: t('main_frame::재고 관리'),
          href: '/zigzin/stock',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_carry_out',
          label: t('main_frame::반출 관리'),
          href: '/zigzin/carry_out',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_additional_item',
          label: t('main_frame::추가 상품 관리'),
          href: '/zigzin/additional_item',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_sub_site_id_allowed,
        },
        {
          id: 'zigzin_order',
          label: t('main_frame::주문검색'),
          href: '/zigzin/order',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_MANAGEMENT_READ], info.allowed_resource_list) &&
            is_sub_site_id_allowed,
        },
        {
          id: 'zigzin_fee_settlement',
          label: t('main_frame::이용료 내역'),
          href: '/zigzin/fee_settlement',
          is_allowed:
            is_zigzin_allowed &&
            checkRes([SellerResource.ZONLY_FEE_HISTORY], info.allowed_resource_list) &&
            is_main_site_id_allowed,
        },
        {
          id: 'zigzin_inbound_problem_solved',
          label: t('main_frame::입고 문제 보고'),
          href: '/zigzin/inbound_problem_solved',
          is_allowed:
            is_zigzin_allowed && info && (info.id === PRODUCTION_ZIGZIN_SHOP_ID || info.id === ALPHA_ZIGZIN_SHOP_ID),
        },
      ],
    },
  ];
}
