import { IconBeta, IconNew, colors, semantic_colors, shapes, text_styles } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mergeRefs } from '@react-aria/utils';
import React, { ForwardedRef, KeyboardEventHandler, ReactNode, useCallback, useMemo, useRef } from 'react';
import { MenuEntry } from './types';
import { hasSelectedChildren } from './utils';
import { ShopLink } from '@/components/route/ShopLink';
import { createDescendantContext } from '@/utils/descendant';

export interface MenuChildItemProps {
  entry: MenuEntry;
  onClick?: (entry: MenuEntry) => void;
  selected_id: string | null;
  with_descendant?: boolean;
}

type MenuChildItemElement = HTMLAnchorElement | HTMLButtonElement;

export const [MenuListProvider, useMenuListContext, useMenuListDescendants, useMenuListDescendant] =
  createDescendantContext();

export const MenuChildItem: React.FC<MenuChildItemProps> = ({ entry, onClick, selected_id, with_descendant }) => {
  const common_props = {
    entry,
    onClick,
    selected_id,
  };

  if (with_descendant) {
    return <MenuChildItemWithDescendant {...common_props} with_descendant />;
  } else {
    return <MenuChildItemWithoutDescendant {...common_props} />;
  }
};

const MenuChildItemWithoutDescendant: React.FC<MenuChildItemProps> = ({ entry, onClick, selected_id }) => {
  const label = (
    <>
      {entry.label}
      {entry.is_new && (
        <StyledIcon>
          <IconNew size={8} color={semantic_colors.brand.primary} />
        </StyledIcon>
      )}
      {entry.is_beta && (
        <StyledIcon>
          <IconBeta size={21} color={semantic_colors.accent.primary} />
        </StyledIcon>
      )}
      {entry.badge}
    </>
  );
  return <BaseMenuChildItem label={label} entry={entry} onClick={onClick} selected_id={selected_id} />;
};

const MenuChildItemWithDescendant: React.FC<MenuChildItemProps> = ({ entry, onClick, selected_id }) => {
  const menu_ref = useRef<MenuChildItemElement>(null);
  const { descendants, index: descendant_index, register } = useMenuListDescendant();

  const handleKeydown: KeyboardEventHandler<any> = useCallback(
    (event) => {
      if (descendants) {
        const keyMap: Record<string, React.KeyboardEventHandler> = {
          ArrowDown: () => {
            descendants.nextEnabled(descendant_index)?.node.focus();
          },
          ArrowUp: () => {
            descendants.prevEnabled(descendant_index)?.node.focus();
          },
        };
        const action = keyMap[event.key];
        if (action && !event.nativeEvent.isComposing) {
          event.preventDefault();
          action(event);
        }
      }
    },
    [descendant_index, descendants],
  );

  return (
    <BaseMenuChildItem
      ref={mergeRefs(register, menu_ref) as ForwardedRef<MenuChildItemElement>}
      entry={entry}
      selected_id={selected_id}
      label={entry.label}
      onClick={onClick}
      with_descendant
      onKeyDown={handleKeydown}
    />
  );
};

const BaseMenuChildItem = React.forwardRef<
  MenuChildItemElement,
  MenuChildItemProps & {
    label: ReactNode;
    onKeyDown?: React.KeyboardEventHandler<MenuChildItemElement>;
  }
>(({ entry, selected_id, label, with_descendant, onClick, onKeyDown }, ref) => {
  const has_selected = useMemo(() => hasSelectedChildren(entry, selected_id), [entry, selected_id]);
  const handleClick = useCallback(() => {
    entry.onClick?.();
    onClick?.(entry);
  }, [entry, onClick]);

  const common_props = {
    is_selected: has_selected,
    onClick: handleClick,
    onKeyDown,
    ...(with_descendant && { is_plain: true }),
  };

  if (entry.items != null) {
    return (
      <StyledMenuGroup>
        <StyledMenuGroupText>{label}</StyledMenuGroupText>
        {entry.items.map((child) => (
          <MenuChildItem key={child.id} entry={child} selected_id={selected_id} onClick={onClick} />
        ))}
      </StyledMenuGroup>
    );
  }
  if (entry.href != null && entry.href.startsWith('http')) {
    return (
      <StyledMenuA ref={ref as ForwardedRef<HTMLAnchorElement>} href={entry.href} target='_blank' {...common_props}>
        {label}
      </StyledMenuA>
    );
  } else if (entry.href != null) {
    return (
      <StyledMenuLink ref={ref as ForwardedRef<HTMLAnchorElement>} to={entry.href} {...common_props}>
        {label}
      </StyledMenuLink>
    );
  }
  return (
    <StyledMenuItem ref={ref as ForwardedRef<HTMLButtonElement>} type='button' {...common_props}>
      {label}
    </StyledMenuItem>
  );
});

const StyledMenuItem = styled('button', {
  shouldForwardProp: (prop) => prop !== 'is_selected' && prop !== 'is_plain',
})<{ is_selected?: boolean; is_plain?: boolean }>`
  display: block;
  width: 100%;
  margin: 2px 0;
  padding: ${({ is_plain }) => (is_plain ? '4px 10px' : '4px 16px')};
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  transition: 0.1s background-color linear;
  ${({ is_plain }) => !is_plain && `${shapes.border_radius.small}`}
  ${text_styles.Body_13_Regular};
  &:hover,
  &:focus-visible {
    background-color: ${colors.gray20};
  }
  &:active {
    background-color: ${colors.gray30};
  }
  &:visited {
    color: ${semantic_colors.content.primary};
  }

  ${({ is_selected, is_plain }) =>
    is_selected &&
    !is_plain &&
    css`
      color: ${semantic_colors.accent.primary};
      ${text_styles.Body_13_SemiBold};
      background-color: ${colors.blue100};
      &:hover,
      &:focus-visible,
      &:active {
        background-color: ${colors.blue100};
      }
      &:visited {
        color: ${semantic_colors.accent.primary};
      }
    `}
`;

const StyledMenuLink = StyledMenuItem.withComponent(ShopLink);
const StyledMenuA = StyledMenuItem.withComponent('a');

const StyledMenuGroup = styled.div`
  margin-top: 16px;
  &:first-child {
    margin: 0;
  }
`;

const StyledMenuGroupText = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px 4px;
  color: ${semantic_colors.content.tertiary};
  ${text_styles.Body_12_SemiBold};
  &:after {
    display: block;
    content: '';
    flex: 1 0 0px;
    margin-left: 8px;
    height: 1px;
    background-color: ${semantic_colors.border.primary};
  }
`;

const StyledIcon = styled.span`
  display: inline-block;
  margin-left: 2px;
  vertical-align: 5px;
  line-height: 0;
`;
