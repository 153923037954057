import { TopBar, Typography } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '@/hooks/useAccount';
import { usePbl } from '@/hooks/usePbl';
import { PartnersBridge } from '@/utils/app_utils';

interface Props {
  kind: Typography;
}
const PartnerLoungeLink: FC<Props> = ({ kind }) => {
  const { t } = useTranslation('account');
  const { pbl } = usePbl();
  const { is_logined } = useAccount();

  return (
    <TopBar.ActionButton
      kind={kind}
      onClick={() => {
        PartnersBridge.callSystemBrowserDeepLink(is_logined ? 'https://bit.ly/3Az9sTK' : 'https://bit.ly/3c3h4Ej');
        pbl({
          navigation: 'gnb_n_top_bar',
          category: 'click',
          object_id: 'partner_lounge',
          object_section: 'top_bar',
        });
      }}
    >
      {t('파트너라운지')}
    </TopBar.ActionButton>
  );
};

export default PartnerLoungeLink;
