import { Badge, HStack, Tooltip } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { drawer_menu_open_atom } from '../atom';
import { LNB_TOOLTIP_TYPE, useGetLnbTooltip } from './hooks/useGetLnbTooltip';
import { ItemRow } from './ItemRow';
import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';

export const ShopInfoInsight: React.FC = () => {
  const shop_prefix = useShopPrefix();
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();

  const { is_zigzag: is_zigzag_site } = useInfo();
  const { is_zigzag } = useAccount();
  const is_shown = is_zigzag || is_zigzag_site;
  const { is_partner_plus } = useGetPlan();
  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);
  const { text, is_show, hideTooltip } = useGetLnbTooltip(LNB_TOOLTIP_TYPE.PARTNER_PLUS);
  const PartnerSubscriptionContent = is_partner_plus ? (
    <div>
      <ItemRow
        title={
          <HStack spacing={4} alignment='center'>
            {t('파트너플러스')}
            <Badge color={'green'} size='small' kind='fill'>
              {t('구독중')}
            </Badge>
          </HStack>
        }
        show_right
        to={`${shop_prefix}${PartnerPlusNavUrl.MANAGEMENT_BENEFIT}`}
        onClick={() => {
          const partner_plus_insight_menu_div = document.getElementById('insight');
          partner_plus_insight_menu_div?.scrollIntoView({ behavior: 'smooth' });

          pbl({
            category: 'click',
            navigation: 'gnb_n_top_bar',
            object_id: 'pages',
            object_section: 'navigations',
            extra: JSON.stringify({
              item: '파트너플러스 구독중',
            }),
          });
          hideTooltip();
        }}
      />
    </div>
  ) : (
    <></>
  );
  if (!is_shown) {
    return null;
  }
  if (is_partner_plus) {
    if (is_show) {
      return (
        <Tooltip
          opened={is_show}
          content={<TextBox>{text}</TextBox>}
          openerTriggerEvent='none'
          placement='right'
          kind='accent'
          canClickOutside={false}
          zIndex={90}
          onClose={() => {
            if (is_show) {
              hideTooltip();
            }
          }}
        >
          {PartnerSubscriptionContent}
        </Tooltip>
      );
    }
    return PartnerSubscriptionContent;
  }
  return (
    <div>
      <ItemRow
        title={t('파트너플러스 소개')}
        show_right
        to={`${shop_prefix}${PartnerPlusNavUrl.INTRO}`}
        onClick={() => {
          pbl({
            category: 'click',
            navigation: 'gnb_n_top_bar',
            object_id: 'pages',
            object_section: 'navigations',
            extra: JSON.stringify({
              item: '파트너플러스 소개',
            }),
          });
          hideTooltip();

          if (!is_desktop) {
            onDrawerOpen(false);
          }
        }}
      />
    </div>
  );
};

const TextBox = styled.div`
  max-width: 200px;
  word-break: keep-all;
`;
