import { Button, text_styles, colors, semantic_colors, HStack } from '@croquiscom/pds';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightFeedbackPopover } from './InsightFeedbackPopover';
import { useLocation } from '@/components/route/LegacyRouterCompat';
import { useModalState } from 'rui/pastel/unstable/modal';

export const InsightFeedbackButton = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [{ isOpen, onClose }, onToggle] = useModalState();
  const handleClick = () => {
    onToggle(!isOpen);
  };

  useLayoutEffect(() => {
    onClose();
  }, [location.pathname]);

  return (
    <HStack
      spacing={10}
      css={{
        position: 'relative',
      }}
    >
      <InsightFeedbackPopover isOpen={isOpen} onClose={onClose} pageTitle={pageTitle} />
      {isOpen ? (
        <Button css={[RoundedButton, OpenedButton]} onClick={handleClick}>
          {t('피드백')}
        </Button>
      ) : (
        <Button css={[RoundedButton]} onClick={handleClick}>
          {t('피드백')}
        </Button>
      )}
    </HStack>
  );
};

const RoundedButton = `
  padding: 6px 16px;
  border: 2px solid ${semantic_colors.background.surface};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  background-color: ${semantic_colors.background.surface};
  color: ${colors.gray800};
  cursor: pointer;
  ${text_styles.Body_14_SemiBold};

  &:hover {
    border: 2px solid ${colors.gray30};
    background-color: ${colors.gray30};
  }
  &:active {
    border: 2px solid #E6E6E6;
    background-color: #E6E6E6;
  }
`;
const OpenedButton = `
  padding: 6px 16px;
  border: 2px solid ${colors.blue500};
  background-color: ${colors.white};
  color: ${semantic_colors.accent.primary};
  &:hover {
    border: 2px solid ${colors.blue500};
  }
  &:active {
    border: 2px solid ${colors.blue500};
  }
`;
