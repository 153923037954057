import { IconArrowTriangleDown, Popover, TopBar, colors, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountBarAccountShopInfo } from './AccountShopInfo';
import AccountInfoEditorModal from './modal/AccountInfoEditorModal';
import AccountInfoModal from './modal/AccountInfoModal';
import { main_domain_atom } from '@/atoms/main_domain';
import { useAccount } from '@/hooks/useAccount';
import { usePbl } from '@/hooks/usePbl';

export interface AccountBarAccountSectionProps {
  logout: () => void;
}

export const AccountBarAccountSection: React.FC<AccountBarAccountSectionProps> = ({ logout }) => {
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const main_domain = useAtomValue(main_domain_atom);
  const { identifier, is_zigzag } = useAccount();
  const [is_info_open, setIsInfoOpen] = useState(false);
  const [is_editor_open, setIsEditorOpen] = useState(false);
  return (
    <>
      <Popover
        arrow={false}
        content={({ close }) => (
          <StyledDropdown>
            {!!main_domain && <AccountBarAccountShopInfo />}
            {!is_zigzag && (
              <StyledDropdownItem
                type='button'
                onClick={() => {
                  setIsEditorOpen(true);
                  pbl({
                    navigation: 'gnb_n_top_bar',
                    category: 'click',
                    object_id: 'account_info',
                    object_section: 'top_bar',
                  });
                  close();
                }}
              >
                {t('계정 정보 수정')}
              </StyledDropdownItem>
            )}
            <StyledDropdownItem
              type='button'
              onClick={() => {
                setIsInfoOpen(true);
                pbl({
                  navigation: 'gnb_n_top_bar',
                  category: 'click',
                  object_id: 'access_history',
                  object_section: 'top_bar',
                });
                close();
              }}
            >
              {t('접속 이력')}
            </StyledDropdownItem>
            <StyledDropdownItem
              type='button'
              onClick={() => {
                logout();
                pbl({
                  navigation: 'gnb_n_top_bar',
                  category: 'click',
                  object_id: 'logout',
                  object_section: 'top_bar',
                });
                close();
              }}
            >
              {t('로그아웃')}
            </StyledDropdownItem>
          </StyledDropdown>
        )}
        padding={8}
      >
        <TopBar.ActionButton>
          {identifier}
          <IconArrowTriangleDown size={12} color={semantic_colors.content.primary_inverse} />
        </TopBar.ActionButton>
      </Popover>
      <AccountInfoModal isOpen={is_info_open} onClose={() => setIsInfoOpen(false)} />
      <AccountInfoEditorModal isOpen={is_editor_open} onClose={() => setIsEditorOpen(false)} />
    </>
  );
};

const StyledDropdown = styled.div`
  width: 188px;
  text-align: left;
`;

const StyledDropdownItem = styled.button`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  align-items: center;
  ${text_styles.Body_13_Regular};
  background-color: ${colors.white};
  border: none;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  text-align: left;
  border-radius: 8px;
  text-decoration: none;
  &:visited {
    color: ${semantic_colors.content.primary};
  }
  &:hover,
  &:focus-visible {
    background-color: ${colors.gray10};
  }
  &:active {
    background-color: ${colors.gray20};
  }
`;
