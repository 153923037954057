import { semantic_colors, State } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

export interface ErrorPageProps {
  message?: React.ReactNode;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  return (
    <StyledRoot>
      <StyledState
        kind='warning'
        size={40}
        description={message ?? '이용에 불편을 드려 죄송합니다. 잠시후 다시 시도해주세요.'}
      />
    </StyledRoot>
  );
};

export default ErrorPage;

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding: 200px 10px;
  background-color: ${semantic_colors.background.surface};
`;

const StyledState = styled(State)`
  flex: 1;
  width: 100%;
  justify-content: center;
`;
