import { VStack, colors } from '@croquiscom/pds';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import dayjs from 'dayjs';
import React, { useLayoutEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ShopProductDailyImpressionsSummaryDuringOptimization } from 'api';

interface DataType {
  x: string;
  y: number;
}

interface BoostingProductChartProps {
  stat_data: ShopProductDailyImpressionsSummaryDuringOptimization[];
}

const BoostingProductChart = ({ stat_data }: BoostingProductChartProps) => {
  const labels = stat_data.map((item) => item.date_ymd);
  useLayoutEffect(() => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  }, []);

  const chart_data: ChartData<'bar', DataType[]> = {
    labels,
    datasets: [
      {
        label: '일반 노출 수',
        data: stat_data.map((item) => ({
          x: item.date_ymd,
          y: item.impressions_summary.total_impressions - item.impressions_summary.estimated_increased_impressions,
        })),
        backgroundColor: () => {
          return colors.gray50;
        },
        barThickness: 12,
        borderSkipped: false,
      },
      {
        label: '추가 노출 수',
        data: stat_data.map((item) => ({
          x: item.date_ymd,
          y: item.impressions_summary.estimated_increased_impressions,
        })),
        backgroundColor: colors.green400,
        borderColor: colors.green400,
        barThickness: 12,
        borderSkipped: false,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'x',
        padding: 10,
        cornerRadius: 4,
        titleFont: {
          size: 12,
          weight: '500',
          lineHeight: '20px',
        },
        titleColor: colors.gray250,
        bodyFont: {
          size: 13,
          weight: '600',
          lineHeight: '22px',
        },
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 4,
        usePointStyle: true,
        bodyColor: colors.gray30,
        backgroundColor: colors.gray800,
        position: 'nearest',
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        callbacks: {
          title([first]) {
            const date = first ? dayjs(first.label, 'YYYY-MM-DD').format('YYYY.MM.DD (ddd)') : '';
            const is_boosted = stat_data[first.dataIndex]?.is_boosted;
            return `${date} 노출 확대 ${is_boosted ? '적용' : '미적용'}`;
          },
          beforeBody(data) {
            return `총 노출 수 ${data
              .reduce((sum, item) => sum + (item.raw as { y: number }).y, 0)
              .toLocaleString()}회`;
          },
          label(item) {
            const datasetLabel = item.dataset.label;
            const value = item.formattedValue;
            const isBoosted = stat_data[item.dataIndex]?.is_boosted;

            if (isBoosted) {
              return `${datasetLabel} ${value}회`;
            }
            return '';
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
            lineHeight: '9px',
          },
          color: colors.gray550,
          callback(index) {
            return dayjs(labels[index], 'YYYY-MM-DD').format('MM.DD(dd)');
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        min: 0,
        stacked: true,
        ticks: {
          font: {
            size: 11,
            weight: '600',
            lineHeight: '18px',
          },
          color: colors.gray600,
        },
      },
    },
  };

  return (
    <VStack height={206} width='100%'>
      <Bar options={options} data={chart_data} />
    </VStack>
  );
};

export default BoostingProductChart;
