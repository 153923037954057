import { HStack, IconNew, LineTab, LineTabs, PageHeader, semantic_colors } from '@croquiscom/pds';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';

const TAB_ITEMS = [
  {
    label: '스토어 수수료 현황',
    value: 'shop',
  },
  {
    label: '상품 수수료 현황',
    value: 'product',
  },
  {
    label: (
      <HStack spacing={4} alignment='center'>
        추가 수수료 현황
        <IconNew size={8} color={semantic_colors.accent.primary} />
      </HStack>
    ),
    value: 'additional',
  },
  {
    label: '정산 정보',
    value: 'payment',
  },
];

const SellerPaymentHeader: FC = () => {
  const history = useHistory();
  const query = useQueryParams();
  const [active_tab, setActiveTab] = useState<string>(query.get('type') || 'shop');
  return (
    <>
      <PageHeader
        border={false}
        sectionTitleProps={{
          title: '정산 정보',
          description:
            '수수료 금액은 최종 결제금액 (상품금액 + 배송비) x 판매수수료율로 계산되며 VAT는 별도로 적용됩니다.',
        }}
      />
      <LineTabs
        activeTabId={active_tab}
        onChange={(id) => {
          if (id) {
            setActiveTab(id);
            history.push(`${history.location.pathname}?type=${id}`);
          }
        }}
      >
        {TAB_ITEMS.map((item) => (
          <LineTab id={item.value}>{item.label}</LineTab>
        ))}
      </LineTabs>
    </>
  );
};

export default SellerPaymentHeader;
