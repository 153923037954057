import { IconCircleInfo } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId, isNotAllowedByShopDepartment } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment } from '@/types/info';
import { SellerResource } from 'api';

export function createInfoMenuTree({ info, account }: CreateMenuTreeOptions): MenuEntry[] {
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);
  return [
    {
      id: 'info',
      label: t('main_frame::내 스토어 정보 관리'),
      icon: <IconCircleInfo size={16} color='currentColor' />,
      is_allowed: is_site_id_allowed,
      is_support_app: true,
      items: [
        {
          id: 'info_settings',
          label: t('main_frame::스토어 설정'),
          is_support_app: true,
          items: [
            {
              id: 'info_trait',
              label: t('main_frame::스토어 기본 정보 관리'),
              href: '/info',
              is_allowed: checkRes(
                [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_BASIC, SellerResource.SHOP_SETTINGS_TRAIT],
                info.allowed_resource_list,
              ),
              is_support_app: true,
            },
            {
              id: 'shop_contract',
              label: '약정 관리',
              href: '/shop_coupon_contract',
              is_allowed:
                checkRes([SellerResource.ALL], info.allowed_resource_list) &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
              is_new: true,
            },
            {
              id: 'info_shop_auth_key',
              label: t('main_frame::API 인증키 관리'),
              href: '/info/shop_auth_key',
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_EXPANSION],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                (account?.is_beta || info.type === 'ZPAY') &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
            },
            {
              id: 'info_shop_sync',
              label: t('main_frame::스토어 연동'),
              href: '/info/shop_sync',
              is_allowed:
                checkRes(
                  [
                    SellerResource.SHOP_SETTINGS,
                    SellerResource.SHOP_SETTINGS_BASIC,
                    SellerResource.SHOP_SETTINGS_SHOP_SYNC_READ,
                  ],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                !!info.shop_sync_allowed &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
            },
          ],
        },
        {
          id: 'info_security',
          label: t('main_frame::계정 및 보안'),
          items: [
            {
              id: 'info_manage_managers',
              label: t('main_frame::매니저 계정/권한 관리'),
              href: '/info/manage_managers',
              is_allowed: checkRes([SellerResource.ALL], info.allowed_resource_list) && !info.is_parent,
            },
            {
              id: 'info_agency',
              label: t('main_frame::대행사 계정/권한 관리'),
              href: '/info/agency',
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_EXPANSION],
                  info.allowed_resource_list,
                ) && !info.is_parent,
            },
            {
              id: 'info_story',
              label: t('main_frame::스토리 업로드 권한 관리'),
              href: '/info/story',
              is_allowed: checkRes(
                [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_BASIC, SellerResource.SHOP_SETTINGS_TRAIT],
                info.allowed_resource_list,
              ),
            },
            {
              id: 'info_access_history',
              label: t('main_frame::개인 정보 열람 기록'),
              href: '/info/access_history',
              is_allowed: account?.is_zigzag === true && !info.is_parent,
            },
          ],
        },
        {
          id: 'info_seller',
          label: t('main_frame::판매자 정보 관리'),
          is_support_app: true,
          items: [
            {
              id: 'info_contact',
              label: t('main_frame::담당자 정보'),
              href: '/info/contact',
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_EXPANSION],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
            },
            {
              id: 'info_shipping_address',
              label: t('main_frame::배송 정보'),
              href: '/info/shipping_address',
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_EXPANSION],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                (account?.is_beta || info.type === 'ZPAY'),
            },
            {
              id: 'info_seller_payment',
              label: t('main_frame::정산 정보'),
              href: '/info/seller_payment',
              is_new: true,
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_EXPANSION],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                (account?.is_beta || info.type === 'ZPAY'),
            },
            {
              id: 'info_file_register',
              label: t('main_frame::정보 변경 신청'),
              href: '/info/file_register',
              is_allowed: checkRes([SellerResource.SHOP_SETTINGS], info.allowed_resource_list) && !info.is_parent,
              is_support_app: true,
            },
            {
              id: 'info_brand_license',
              label: t('main_frame::라이센스'),
              href: '/info/brand_license',
              is_allowed:
                checkRes(
                  [SellerResource.SHOP_SETTINGS, SellerResource.SHOP_SETTINGS_BASIC],
                  info.allowed_resource_list,
                ) &&
                !info.is_parent &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
            },
          ],
        },
      ],
    },
  ];
}
