import { BaseText, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { GetImportantSellerNoticeListQuery } from '@/api';
import { NoticeCategoryLabel } from '@/components/notice/constants';
import { ShopLink } from '@/components/route/ShopLink';
import { usePbl } from '@/hooks/usePbl';

type SellerNotice = GetImportantSellerNoticeListQuery['seller_notice_list']['item_list'][number];

export interface NoticeItemProps {
  'notice': SellerNotice;
  'aria-hidden'?: boolean;
}

export const NoticeItem: React.FC<NoticeItemProps> = ({ notice, ...restProps }) => {
  const { pbl } = usePbl();
  const sendNoticePbl = (notice_id: string) => {
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'notice',
      object_section: 'top_bar',
      extra: JSON.stringify({ notice_id, is_important: true }),
    });
  };

  return (
    <Notice
      as='div'
      kind='Body_12_Medium'
      color={semantic_colors.content.primary_inverse}
      {...restProps}
      onClick={() => sendNoticePbl(notice.id)}
    >
      <NoticeLink to={`/notice/${notice.id}`}>
        [{NoticeCategoryLabel[notice.category]}] {notice.title}
      </NoticeLink>
      <span>{dayjs(notice.date_published).utcOffset(9).format('YYYY.MM.DD')}</span>
    </Notice>
  );
};

const Notice = styled(BaseText)`
  margin-left: 8px;
  letter-spacing: -0.2px;

  & span {
    margin-left: 6px;
    color: ${colors.gray400};
  }
`;

const NoticeLink = styled(ShopLink)`
  color: ${semantic_colors.content.primary_inverse};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: ${semantic_colors.content.primary_inverse};
  }
`;
