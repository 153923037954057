import {
  TopBar,
  HStack,
  Divider,
  IconThreeBars,
  semantic_colors,
  colors,
  ImagePartnerCenterSymbolWhNew,
  ImagePartnerCenterLogoWhNew,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PartnerLoungeLink from '@/app/rui/components/main_frame/accountbar/PartnerLoungeLink';
import { AccountBarProps } from '@/app/rui/components/main_frame/accountbar/types';
import { drawer_menu_open_atom, drawer_menu_presence_atom } from '@/app/rui/components/main_frame/atom';
import AlertBox from '@/components/alert_box';
import { Config } from '@/config';
import { useAccount } from '@/hooks/useAccount';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { reset_button_css } from '@/utils';

interface MobileAccountBarProps extends AccountBarProps {
  logout: () => void;
}

const MobileAccountBar: React.FC<MobileAccountBarProps> = ({ logout }) => {
  const { t } = useTranslation('account');
  const { pbl } = usePbl();
  const shop_prefix = useShopPrefix();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);
  const has_drawer = useAtomValue(drawer_menu_presence_atom);
  const { is_logined } = useAccount();
  const main_domain = Config.shop_main_domain;

  const accountbar_component = useMemo(() => {
    if (is_logined && (!!main_domain || has_drawer)) {
      return (
        <TopBar.ActionArea>
          <HStack spacing={16}>
            {!!main_domain && <AlertBox is_mobile />}
            <TopBar.ActionButton role='button' aria-label='메뉴 열기'>
              <ButtonIcon onClick={() => onDrawerOpen(true)}>
                <IconThreeBars size={24} color={semantic_colors.content.primary_inverse} />
              </ButtonIcon>
            </TopBar.ActionButton>
          </HStack>
        </TopBar.ActionArea>
      );
    }

    if (is_logined) {
      return (
        <TopBar.ActionArea>
          <TopBar.ActionButton kind='Body_12_SemiBold' onClick={logout}>
            {t('로그아웃')}
          </TopBar.ActionButton>
          <DarkenDivider />
          <PartnerLoungeLink kind='Body_12_SemiBold' />
        </TopBar.ActionArea>
      );
    }

    return (
      <TopBar.ActionArea>
        <PartnerLoungeLink kind='Body_12_SemiBold' />
      </TopBar.ActionArea>
    );
  }, [is_logined, main_domain]);

  return (
    <TopBar.Container height={56}>
      <HStack spacing={8} alignment='center'>
        <TopBar.Title
          href='/'
          onClick={() => {
            pbl({ navigation: 'gnb_n_top_bar', category: 'click', object_id: 'home', object_section: 'top_bar' });
          }}
        >
          <ImagePartnerCenterSymbolWhNew />
        </TopBar.Title>
        <StyledLink
          to={main_domain ? `${shop_prefix}/home` : '/'}
          onClick={() => {
            if (main_domain) {
              pbl({
                navigation: 'gnb_n_top_bar',
                category: 'click',
                object_id: 'dashboard',
                object_section: 'top_bar',
              });
            }
          }}
        >
          <ImagePartnerCenterLogoWhNew size={144.1} />
        </StyledLink>
      </HStack>

      {accountbar_component}
    </TopBar.Container>
  );
};

export default MobileAccountBar;

const ButtonIcon = styled.button`
  ${reset_button_css};
  width: 24px;
  height: 24px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const DarkenDivider = () => <Divider direction='vertical' length={10} color={colors.gray550} />;
