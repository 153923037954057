import {
  VStack,
  BaseText,
  HStack,
  semantic_colors,
  text_styles,
  IconCircleInfo,
  Button,
  IconArrowChevronRight,
  shapes,
  colors,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { ChatBotLogo } from './ChatBotLogo';
import { RoundIconContainer } from './styled';
import ChatBotIcon from '@/assets/images/common/chatbot_icon.png';

interface ChatBotIntroProps {
  openFaq: () => void;
}

export const ChatBotIntro = ({ openFaq }: ChatBotIntroProps) => {
  return (
    <VStack alignment='leading' spacing={8} width='100%'>
      <HStack spacing={8} alignment='center'>
        <RoundIconContainer size='xsmall'>
          <img width={21} src={ChatBotIcon} alt='' />
        </RoundIconContainer>
        <HStack spacing={4}>
          <HStack spacing={4}>
            <HStack spacing={4} alignment='center' height={20}>
              <ChatBotLogo color={semantic_colors.content.primary} />
            </HStack>
          </HStack>
          <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
            {dayjs().format('A hh:mm')}
          </BaseText>
        </HStack>
      </HStack>
      <SC.MessageBox>
        <span>
          안녕하세요, 저는 AI 챗봇이에요.{' '}
          <span role='img' aria-label=''>
            👋
          </span>
          <br />
          파트너센터 서비스에 대해 무엇이든 질문해주세요.
        </span>
        <VStack spacing={12}>
          <HStack spacing={4} alignment='center'>
            <IconCircleInfo size={12} color={semantic_colors.content.tertiary} />
            <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
              챗봇은 실수할 수 있습니다. 답변 내용을 꼭 확인해 주세요.
            </BaseText>
          </HStack>
          <Button endIcon={<IconArrowChevronRight />} kind='outlined_secondary' size='xsmall' fill onClick={openFaq}>
            챗봇 잘 쓰는 방법
          </Button>
        </VStack>
      </SC.MessageBox>
    </VStack>
  );
};

const SC = {
  MessageBox: styled(VStack)`
    padding: 10px 12px 12px 12px;
    width: 100%;
    ${text_styles.Body_14_Medium};
    ${shapes.border_radius.small};
    background-color: ${colors.gray20};
    row-gap: 4px;
    line-height: 1.6;
    white-space: pre-line;
  `,
};
