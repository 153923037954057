import {
  Badge,
  BaseText,
  HStack,
  semantic_colors,
  IconCopy,
  ImageZexpressTextKor,
  IconEyeOn,
  IconCircleWon,
  IconCircleQuestion,
  IconLinkExternal,
  IconArrowIncrease,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { blockedProduct } from '../alert_templates';
import { PRODUCT_GROUP_KEY } from '../constants';
import { useCopyProductId } from '../hooks/useCopyProductId';
import ProductPerformanceButton from './ProductPerformanceButton';
import PromotionProductBadge from './PromotionProductBadge';
import ProductThumbImage from '@/components/catalog/common/ProductThumbImage';
import PreviewModal from '@/components/catalog/modal/PreviewModal';
import ResponsiveHStack from '@/components/common/responsive/ResponsiveHStack';
import ResponsiveText from '@/components/common/responsive/ResponsiveText';
import ResponsiveVStack from '@/components/common/responsive/ResponsiveVStack';
import { SiteId } from '@/constants/site';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  CatalogFulfillmentType,
  CountryCode,
  ShopPriceOptimizationRequiredProductStats,
  ShopPriceOptimizationTargetProductBasicInfo,
} from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

interface BoostingProductInfoProps {
  basic_info: Omit<
    ShopPriceOptimizationTargetProductBasicInfo,
    'current_base_shipping_fee' | 'promotion_discount_price' | 'shipping_fee_for_free_shipping'
  >;
  stats?: ShopPriceOptimizationRequiredProductStats;
  sellable?: boolean;
  group_list: string[];
  has_performance?: boolean;
  is_modal?: boolean;
}

const BoostingProductInfo: React.FC<BoostingProductInfoProps> = ({
  basic_info,
  group_list,
  sellable,
  stats,
  has_performance,
  is_modal,
}) => {
  const [{ isOpen, onOpen, onClose }] = useModalState(false);
  const { is_desktop } = useMediaQuery();
  const is_new = group_list?.includes(PRODUCT_GROUP_KEY.NEW_PRODUCT);
  const is_promotion = group_list?.includes(PRODUCT_GROUP_KEY.PROMOTION_PRODUCT);
  const copyText = useCopyProductId();
  const ellipsis_line = has_performance ? 2 : is_desktop ? (is_modal ? 2 : 4) : 3;

  return (
    <>
      <ResponsiveHStack width='100%' spacing={{ desktop: 16, mobile: 12 }} alignment='top'>
        <SC.ImageWrap>
          <SC.Image image_url={basic_info.image_url} width={72} ratio={1.2} alt='' />
          <SC.PdpLink onClick={onOpen}>
            <IconLinkExternal size={24} color={semantic_colors.content.primary_inverse} />
          </SC.PdpLink>
        </SC.ImageWrap>
        <ResponsiveVStack spacing={{ desktop: has_performance ? 4 : 8, mobile: 4 }}>
          {(sellable === false || is_promotion || is_new) && (
            <HStack spacing={4} alignment='center'>
              {sellable === false && (
                <SC.ClickableBadge
                  onClick={() => {
                    blockedProduct({ basic_info });
                  }}
                >
                  <Badge
                    endIcon={<IconCircleQuestion size={12} />}
                    color='red'
                    shape='rectangle'
                    kind='outline'
                    size='medium'
                  >
                    판매 불가
                  </Badge>
                </SC.ClickableBadge>
              )}
              {is_promotion && <PromotionProductBadge />}
              {is_new && (
                <Badge kind='fill' shape='rectangle' size='medium' color='blue'>
                  신상품
                </Badge>
              )}
            </HStack>
          )}
          <SC.ProductText kind={{ mobile: 'Body_14_Regular' }} ellipsis_line={ellipsis_line}>
            <span onClick={() => copyText(basic_info.product_id)}>
              <SC.ProductNumber
                kind={{ desktop: 'Body_13_Medium', mobile: 'Body_14_Medium' }}
                color={semantic_colors.content.secondary}
              >
                {basic_info.product_id}
                <span>
                  <IconCopy color='currentColor' size={is_desktop ? 14 : 16} />
                </span>
              </SC.ProductNumber>
            </span>
            {basic_info.fulfillment_type === CatalogFulfillmentType.ZIGZIN && (
              <SC.ZigzinImage>
                <ImageZexpressTextKor size={45} />
              </SC.ZigzinImage>
            )}
            <SC.ProductPreviewButton onClick={onOpen} title={basic_info.product_name}>
              {basic_info.product_name}
            </SC.ProductPreviewButton>
          </SC.ProductText>
          {has_performance && <ProductPerformanceButton basic_info={basic_info} />}
          {stats && stats.estimated_increased_gmv + stats.estimated_increased_impressions > 0 && (
            <HStack spacing={8}>
              {stats.estimated_increased_impressions > 0 && (
                <HStack spacing={4} alignment='center' title='예상 추가 노출 수'>
                  <IconEyeOn size={14} color={semantic_colors.content.tertiary} />
                  <HStack alignment='center'>
                    <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
                      {stats.estimated_increased_impressions.toLocaleString() ?? '-'}회
                    </BaseText>
                    <IconArrowIncrease size={12} color={semantic_colors.state.positive} />
                  </HStack>
                </HStack>
              )}
              {stats.estimated_increased_gmv > 0 && (
                <HStack spacing={4} alignment='center' title='예상 추가 매출액'>
                  <IconCircleWon size={14} color={semantic_colors.content.tertiary} />
                  <HStack alignment='center'>
                    <BaseText kind='Body_12_Regular' color={semantic_colors.content.tertiary}>
                      {stats.estimated_increased_gmv.toLocaleString() ?? '-'}원
                    </BaseText>
                    <IconArrowIncrease size={12} color={semantic_colors.state.positive} />
                  </HStack>
                </HStack>
              )}
            </HStack>
          )}
        </ResponsiveVStack>
      </ResponsiveHStack>
      <PreviewModal
        preview_id={basic_info.product_id}
        site_country_list={[{ site_id: Number(SiteId.지그재그), country: CountryCode.KR }]}
        is_open={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default BoostingProductInfo;

const SC = {
  ProductNumber: styled(ResponsiveText)`
    padding-right: 4px;
    cursor: pointer;
    > span {
      padding-left: 2px;
      vertical-align: middle;
    }
  `,
  ProductPreviewButton: styled.span`
    cursor: pointer;
    > span {
      padding-left: 2px;
      vertical-align: middle;
    }
  `,
  ImageWrap: styled.div`
    position: relative;
    width: 72px;
    height: 86px;
    border-radius: 4px;
    flex-shrink: 0;
    overflow: hidden;
  `,
  PdpLink: styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${semantic_colors.background.scrim};
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  `,
  Image: styled(ProductThumbImage)`
    flex-shrink: 0;

    picture > img {
      opacity: 1;
    }
  `,
  ZigzinImage: styled.span`
    padding-right: 4px;
  `,
  ProductText: styled(ResponsiveText)<{ ellipsis_line?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: ${({ ellipsis_line }) => ellipsis_line || 4};
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  `,
  ClickableBadge: styled(HStack)`
    cursor: pointer;
  `,
};
