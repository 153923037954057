import { colors, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import qs from 'qs';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import TableRowItem from './TableRowItem';
import useGetParentShopSettlementMonthlyList from './useGetParentShopSettlementMonthlyList';
import { useInfo } from '@/hooks/useInfo';
import Pagination from 'rui/pastel/Pagination';
import Table from 'rui/pastel/Table';
import { Card, CardHeader } from 'rui/pastel/unstable/card';

const usePagination = () => {
  const [page, setPage] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const handlePaginationClick = (currentPage: number) => {
    history.push(`${history.location.pathname}?${qs.stringify({ page: currentPage + 1 })}`);
  };

  useEffect(() => {
    const query = qs.parse(location.search.slice(1));
    setPage(Number(query?.page) || 1);
  }, [location.search]);

  return { page, handlePaginationClick };
};

const ITEMS_PER_PAGE = 20;
const ListTable = () => {
  const { t } = useTranslation('settlement');
  const { page, handlePaginationClick } = usePagination();
  const { info } = useInfo();
  const { data, isLoading } = useGetParentShopSettlementMonthlyList({
    parent_shop_id: info.id ?? '',
    skip_count: (page - 1) * ITEMS_PER_PAGE,
    limit_count: ITEMS_PER_PAGE,
  });
  const total_page = (data?.page?.total_page ?? 1) - 1;

  return (
    <Card>
      <CardHeader title={t('월별 전체 스토어 정산 목록')} hideLine />
      <VStack p='0 28px 18px'>
        <TableContainer>
          <Table
            loading={isLoading}
            labels={[
              {
                id: 'date_created',
                text: t('작성일자'),
                width: 100,
              },
              {
                id: 'date_ym',
                text: t('집계 내역'),
              },
              {
                id: 'total_settlement_amount',
                text: t('정산 금액'),
                width: 170,
              },
            ]}
            rows={(data?.item_list ?? []).map(
              ({ id, date_created, date_ym, download_url, total_monthly_settlement_amount }) => (
                <TableRowItem
                  key={id}
                  date_created={date_created}
                  date_ym={date_ym}
                  download_url={download_url ?? ''}
                  total_monthly_settlement_amount={total_monthly_settlement_amount}
                />
              ),
            )}
          />
        </TableContainer>
        {total_page > 0 && (
          <Pagination totalPages={total_page} currentPage={page - 1} onPageClick={handlePaginationClick} />
        )}
      </VStack>
    </Card>
  );
};

const TableContainer = styled.div`
  overflow: auto;
  border: 1px solid ${colors.gray30};
  margin-bottom: 10px;
  table {
    thead th {
      border-top: none;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      border-left: none;
    }
    th:nth-last-child(1),
    td:nth-last-child(1) {
      border-right: none;
    }
  }
`;

export default ListTable;
