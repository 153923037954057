import { colors } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { COORDINATED_PRODUCT_TYPE, CoordinatedProductType } from './types';
import {
  CatalogCustomInputOptionInput,
  CatalogDisplayStatus,
  CatalogEntryType,
  CatalogProductOptionType,
  CatalogProductShippingType,
  CatalogSalesStatus,
  CatalogShippingFeeType,
  CountryCode,
  LanguageRegistrationType,
} from '@/api';
import { Emphasis } from '@/components/common/styled';
import TransPlainText from '@/components/common/TransPlainText';
import { SiteId } from '@/constants/site';
import { FormControlHelper } from 'rui/pastel/unstable/FormControl';

export const COLOR_OPTION_NAME = '색상';
export const CUSTOM_OPTION_NAME = '직접입력';

export const CREATE_CATALOG_PRODUCT_GUIDE_LINK =
  'https://docs.google.com/presentation/d/1Dzp7ygBpkW1i_hg2myEP2fr19mVmQh0VFgJRHxvpeMs/edit?usp=sharing';

export const CATALOG_PRODUCT_OPTION_GUIDE_LINK = 'https://partners.kakaostyle.com/help?id=777';

export const DISPLAY_STATUS_ITEMS = [
  {
    label: <TransPlainText ns='catalog' i18nKey='진열' />,
    value: CatalogDisplayStatus.VISIBLE,
  },
  {
    label: <TransPlainText ns='catalog' i18nKey='진열안함' />,
    value: CatalogDisplayStatus.HIDDEN,
  },
];

export const CatalogSalesStatusLabel = {
  [CatalogSalesStatus.CLOSED]: <TransPlainText ns='catalog' i18nKey='판매종료' />,
  [CatalogSalesStatus.ON_SALE]: <TransPlainText ns='catalog' i18nKey='판매중' />,
  [CatalogSalesStatus.PREPARING]: <TransPlainText ns='catalog' i18nKey='판매 대기중' />,
  [CatalogSalesStatus.SOLD_OUT]: <TransPlainText ns='catalog' i18nKey='품절' />,
  [CatalogSalesStatus.SUSPENDED]: <TransPlainText ns='catalog' i18nKey='판매 중단' />,
};

export const SELLER_CHANGEABLE_OPTIONS = [
  CatalogSalesStatus.ON_SALE,
  CatalogSalesStatus.SOLD_OUT,
  CatalogSalesStatus.SUSPENDED,
];

export const BRAND_SELECT_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='브랜드 선택 안내' />,
  contents: [
    {
      text: (
        <Trans ns='catalog'>
          사전에 승인된 브랜드를 선택 하실 수 있습니다.
          <br />
          브랜드 기본 설정을 신청한 경우, 기본값으로 설정한 브랜드가 모든 상품에 고정으로 표시됩니다.
          <br />
          브랜드 추가나 브랜드 기본 설정을 원하실 경우 담당 MD분께 요청 부탁드립니다.
        </Trans>
      ),
    },
  ],
};

export const SELF_PRODUCTION_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='자체 제작 안내' />,
  contents: [
    {
      text: (
        <Trans ns='catalog'>
          도매처에서 구매한 상품과 대비되는 개념으로, 판매자가 직접 기획하고 제작한 상품을 의미합니다. 실제 제작은
          협력업체에서 진행될 수 있으나 구매 상품이나 브랜드 교체 작업만 진행한 상품은 자체 제작 상품이라고 할 수
          없습니다.
          <br />
          <br />
          해당 정보는 고객에게 이러한 상품만을 노출하기 위해 사용됩니다. 허위로 정보 등록 시 상품 비노출, 페널티 부과
          등의 불이익이 있을 수 있으므로 신중하게 선택해 주세요.
        </Trans>
      ),
    },
  ],
};

export const SHIPPING_FEE_ITEMS = [
  { label: <TransPlainText ns='catalog' i18nKey='무료배송' />, value: CatalogShippingFeeType.FREE_SHIPPING },
  {
    label: <TransPlainText ns='catalog' i18nKey='스토어 배송 정보 설정에 따름' />,
    value: CatalogShippingFeeType.SHOP_POLICY,
  },
];

export const ReturnShippingFeeValue = {
  FREE: 'free',
  PAYED: 'payed',
};

export const RETURN_SHIPPING_FEE_ITEMS = [
  { label: <TransPlainText ns='catalog' i18nKey='무료반품' />, value: ReturnShippingFeeValue.FREE },
  {
    label: <TransPlainText ns='catalog' i18nKey='스토어 배송 정보 설정에 따름' />,
    value: ReturnShippingFeeValue.PAYED,
  },
];

export const LANGUAGES_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='언어 설정' />,
  contents: [
    {
      text: (
        <Trans ns='catalog'>
          상품명을 영어버전으로 추가 하실 수 있습니다.
          <br />
          영어 설정 시 지그재그 앱에서 영어로 언어를 변경한 유저들에게 노출됩니다.
        </Trans>
      ),
    },
  ],
};

export const CLAIM_POLICY_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='클레임 정책안내' />,
  contents: [
    {
      text: (
        <TransPlainText
          ns='catalog'
          i18nKey='고객에게 안내되는 클레임 정책입니다. 카카오스타일의 표준적인 클레임 처리 기준으로 안내되며, 직진배송 이용시와 스토어배송 상품 등록 시 문구가 다르게 노출됩니다. 해당 내용에 대한 문의가 있으실 경우, 고객센터로 문의해주세요.'
        />
      ),
    },
  ],
};

export const PRODUCT_CODE_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='자체상품코드' />,
  contents: [
    {
      text: (
        <TransPlainText
          ns='catalog'
          i18nKey='자사에서 관리하시는 자체상품코드가 있을 경우 입력해주세요. 브랜드의 경우 모델명(품번)으로 관리 하실 수 있습니다.'
        />
      ),
    },
  ],
};

export const PRODUCT_TYPE_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='상품종류' />,
  contents: [
    {
      text: (
        <Trans ns='catalog'>
          <Emphasis bold color={colors.gray800}>
            직진배송:&nbsp;
          </Emphasis>
          카카오스타일의 물류/배송 서비스를 이용하는 상품입니다. 직진배송 상품은 판매 대기로 등록되며 직진배송 상품의
          재고가 물류창고에 입고되면 자동으로 판매중으로 전환됩니다.
          <br />
          <Emphasis bold color={colors.gray800}>
            스토어직진배송:&nbsp;
          </Emphasis>
          판매자 물류서비스로 빠르게 배송하는 상품입니다.
          <br />
          <Emphasis bold color={colors.gray800}>
            스토어배송:&nbsp;
          </Emphasis>
          직진배송 서비스를 이용하지 않는 일반 상품입니다.
        </Trans>
      ),
    },
  ],
};

export const OPTION_INFO_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='옵션 추가' />,
  contents: [
    {
      text: (
        <Trans
          ns='catalog'
          i18nKey="1) 최상단에서 카테고리를 먼저 선택해주세요.<br />2) 카테고리에 따라서 기본 옵션항목이 결정됩니다.<br />3) '옵션 목록으로 적용' 선택 시 조합된 옵션이 하단의 옵션 목록에 생성되며, 사용하지 않을 옵션은 선택하여 삭제하실 수 있습니다.<br />4) 옵션별로 이미지, 가격, 재고 등록이 가능합니다. 가격은 추가금액이 아닌 변경금액 총액으로 등록해주세요."
        />
      ),
    },
  ],
};

export const INDIVIDUAL_SHIPPING_FEE_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='개별 배송비' />,
  contents: [
    {
      text: '셀러가 배송정보에 입력한 값과 상관없이 해당 상품은 무료배송으로 설정됩니다.',
    },
  ],
};

export const SHIPPING_DAYS_PER_ITEMS_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='옵션별 발송소요일' />,
  contents: [
    {
      text: '주문 후 상품 출고까지 걸리는 기간을 선택해 주세요. 옵션별 발송소요일 기준으로 발송 기한이 설정됩니다.',
    },
  ],
};

export const DEFAULT_SHIPPING_DAYS_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='발송소요일 기본값' />,
  contents: [
    {
      text: '‘등록’ 이후 새로운 옵션 or 추가상품 추가 시 자동으로 적용할 기본값을 선택해 주세요. 상품 등록 시 따로 설정하지 않은 경우 일반상품 3 영업일, 뷰티상품 1 영업일로 적용됩니다. 기본값 수정 시 기존 옵션의 발송소요일은 변경되지 않습니다.',
    },
  ],
};

export const CHANNEL_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='판매채널 선택' />,
  contents: [
    {
      text: (
        <Trans
          ns='catalog'
          i18nKey='카카오스타일에서 운영하는 다양한 사이트 중 상품을 노출할 채널을 선택할 수 있습니다.<br />현재 이용 중인 서비스 채널 중 선택 가능합니다.'
        />
      ),
    },
  ],
};

export const CATEGORY_ITEM_POPOVER_CONTENT = {
  [CatalogEntryType.CRAWLING_API]: [
    {
      name: '기타',
      title: <TransPlainText ns='catalog' i18nKey='기타' />,
      description: (
        <TransPlainText
          ns='catalog'
          i18nKey="상품에 해당하는 카테고리가 없을 경우, '기타' 항목을 선택해주세요. 추후 신규 카테고리 생성 시 반영됩니다."
        />
      ),
    },
    {
      name: '뷰티',
      title: <TransPlainText ns='catalog' i18nKey='뷰티' />,
      description: (
        <TransPlainText
          ns='catalog'
          i18nKey="'뷰티' 카테고리로 설정할 경우 1차 카테고리 수정이 불가하며 뷰티 수수료율(20%)이 적용됩니다."
        />
      ),
    },
  ],
  [CatalogEntryType.DIRECT]: [
    {
      name: '기타',
      title: <TransPlainText ns='catalog' i18nKey='기타' />,
      description: (
        <TransPlainText
          ns='catalog'
          i18nKey="상품에 해당하는 카테고리가 없을 경우, '기타' 항목을 선택해주세요. 추후 신규 카테고리 생성 시 반영됩니다."
        />
      ),
    },
  ],
};

export const CUSTOM_INPUT_OPTION_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='입력형 옵션 설정' />,
  contents: [
    {
      text: (
        <Trans
          ns='catalog'
          i18nKey='- 고객이 주문할 때 추가로 입력이 필요한 항목을 설정하실 수 있습니다.<br />- 입력형 옵션의 옵션명은 자유롭게 입력 가능하며 옵션값은 텍스트 입력과 숫자입력 유형중 하나를 선택 가능합니다. 필수여부를 체크하시면 주문시 필수등록값으로 지정하실 수 있습니다.'
        />
      ),
    },
  ],
};

export const CUSTOM_INPUT_OPTION_CRAWLING_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='입력형 옵션' />,
  contents: [
    {
      text: (
        <Trans
          ns='catalog'
          i18nKey='- 구매자에게 텍스트를 입력받을 수 있는 옵션입니다. 주로 이니셜을 새길 수 있는 상품에 제공하는 항목입니다.<br />- 솔루션사(카페24, 메이크 샵 등)의 관리자 페이지에서 설정하신 값과 동일하게 설정됩니다.'
        />
      ),
    },
  ],
};

export const LEGACY_MAX_OPTION_ITEM_LENGTH = 1000;
export const MAX_OPTION_ITEM_LENGTH = 500;
export const MAX_OPTION_ITEM_EXTENSION_LENGTH = 7000;
export const MAX_ADDITIONAL_ITEM_LENGTH = 100;
export const MAX_ADDITIONAL_ITEM_NAME_LENGTH = 20;

export const DEFAULT_CUSTOM_INPUT_OPTION: CatalogCustomInputOptionInput = {
  name: '',
  required: false,
  max_length: 50,
  option_type: CatalogProductOptionType.BASIC,
};

export enum CatalogLanguages {
  한국어 = 'ko',
  영어 = 'en',
}

export const CatalogLanguagesLabeler = {
  [CatalogLanguages.한국어]: () => t('catalog::한국어'),
  [CatalogLanguages.영어]: () => t('catalog::영어'),
};

export const CATALOG_LANGUAGE_FORM_VALUES = [
  { labeler: CatalogLanguagesLabeler[CatalogLanguages.한국어], value: CatalogLanguages.한국어 },
  { labeler: CatalogLanguagesLabeler[CatalogLanguages.영어], value: CatalogLanguages.영어 },
];

export const DEFAULT_CATALOG_PRODUCT_LANGUAGE_ITEM = {
  name: '',
  name_registration_type: LanguageRegistrationType.SELLER,
  essentials: [],
  essentials_registration_type: LanguageRegistrationType.SELLER,
  description_registration_type: LanguageRegistrationType.SELLER,
};

export const SEARCH_KEYWORD_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='검색어' />,
  contents: [
    {
      text: (
        <TransPlainText
          ns='catalog'
          i18nKey='유저가 특정 키워드 검색 시 해당 상품이 노출 될 수 있도록 검색어를 입력 하실 수 있습니다.'
        />
      ),
    },
  ],
};

export const STYLES_HELPER: FormControlHelper = {
  title: <TransPlainText ns='catalog' i18nKey='스타일' />,
  contents: [
    {
      text: (
        <TransPlainText
          ns='catalog'
          i18nKey='스타일 정보는 앱 내에서 고객에게 상품을 추천하는 정보로 사용됩니다. 연관성이 낮은 스타일을 입력하거나 미 입력 시 권장 스타일로 자동 변경 될 수 있습니다.'
        />
      ),
    },
  ],
};

export const MAX_KEYWORD_LENGTH = 10;
export const MAX_KEYWORD_LIST_COUNT = 3;
export const MAX_KEYWORD_LIST_COUNT_FOR_PARTNER_PLUS = 10;
export const MIN_DESCRIPTION_LENGTH = 10;

const POSTY_CATEGORIES = {
  men_underwear_posty: <TransPlainText ns='catalog' i18nKey='남성속옷' />,
  men_pajamas_posty: <TransPlainText ns='catalog' i18nKey='남성파자마' />,
  golf_posty: <TransPlainText ns='catalog' i18nKey='골프' />,
  outdoor_posty: <TransPlainText ns='catalog' i18nKey='아웃도어' />,
  sports_posty: <TransPlainText ns='catalog' i18nKey='스포츠' />,
  men_posty: <TransPlainText ns='catalog' i18nKey='남성' />,
  luxuries_posty: <TransPlainText ns='catalog' i18nKey='명품' />,
};

export const POSTY_CATEGORY_KEYS = Object.keys(POSTY_CATEGORIES);
export const POSTY_CATEGORY_NAMES = Object.values(POSTY_CATEGORIES);

export const MAX_SIZE_INPUT_LENGTH = 50;

export const USE_ADDITIONAL_ITEM_STATUS_ITEMS = [
  {
    label: <TransPlainText ns='catalog' i18nKey='예' />,
    value: true,
  },
  {
    label: <TransPlainText ns='catalog' i18nKey='아니오' />,
    value: false,
  },
];

export const DEFAULT_SHIPPING_DAYS = 3;
export const DEFAULT_BEAUTY_SHIPPING_DAYS = 1;

export const SHIPPING_TYPE_ITEMS = [
  { label: <TransPlainText ns='catalog' i18nKey='일반 배송' />, value: CatalogProductShippingType.GENERAL },
  {
    label: <TransPlainText ns='catalog' i18nKey='주문 제작 후 배송' />,
    value: CatalogProductShippingType.MAKE_TO_ORDER,
  },
  { label: <TransPlainText ns='catalog' i18nKey='설치 배송' />, value: CatalogProductShippingType.INSTALLATION },
  {
    label: <TransPlainText ns='catalog' i18nKey='해외 배송' />,
    value: CatalogProductShippingType.INTERNATIONAL,
  },
];
export const MAX_COORDINATED_PRODUCTS_COUNT = 20;
export const MAX_ORIGINAL_PRODUCTS_COUNT = 1;

export const ZIGZAG_KR_KEY = `${SiteId.지그재그}_${CountryCode.KR}` as const;
export const FBK_KR_KEY = `${SiteId.패션_바이_카카오}_${CountryCode.KR}` as const;
export const POSTY_KR_KEY = `${SiteId.포스티}_${CountryCode.KR}` as const;
export const KAKAOTALK_SHOPPING_KR_KEY = `${SiteId.카카오톡_쇼핑하기}_${CountryCode.KR}` as const;
export const KAKAOTALK_SHOPPING_SITE_COUNTRY = {
  site_id: Number(SiteId.카카오톡_쇼핑하기),
  country: CountryCode.KR,
};

export const ADDITIONAL_OPTION_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY];
export const BEAUTY_SAMPLE_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY];
export const PREORDER_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY];
export const SHOP_SYNC_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY, FBK_KR_KEY];
export const FREE_TAX_TYPE_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY];
export const OVERSEAS_PURCHASE_SUPPORTED_SITE_COUNTRY_KEYS: `${string}_${string}`[] = [ZIGZAG_KR_KEY, POSTY_KR_KEY];

export const ONE_DEPTH_CATEGORY_KEYS = {
  /** 패션의류 */
  FASHION_CLOTHING: 'fashion_clothing',
  /** 패션잡화 */
  FASHION_GOODS: 'fashion_goods',
  /** 뷰티 */
  BEAUTY: 'beauty',
};

export const COORDINATED_PRODUCT_TYPES: CoordinatedProductType[] = Object.values(COORDINATED_PRODUCT_TYPE);
