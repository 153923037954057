import { Button, ButtonProps, Tooltip } from '@croquiscom/pds';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUBSCRIPTION_PRODUCT_ID } from './constants';
import CouponSubscriptionModal from './CouponSubscriptionModal';
import { GetShopSubscriptionPriceQuery, IssuedCouponFragment } from '@/api';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useScrollDebounce } from 'rui/components/main_frame/hooks/useScrollDebounce';
import { useModalState } from 'rui/pastel/unstable/modal';

interface IntroSubscribeButtonProps {
  fill?: boolean;
  issued_coupon?: IssuedCouponFragment | null;
  button_kind?: ButtonProps['kind'];
  onSubscribeButtonClick?: () => void;
  children?: React.ReactNode;
  promotion_price?: GetShopSubscriptionPriceQuery['getShopSubscriptionPrice']['promotion_price'];
  has_tooltip?: boolean;
  has_subscription?: boolean;
}

const IntroSubscribeButton: React.FC<IntroSubscribeButtonProps> = ({
  fill,
  issued_coupon,
  button_kind = 'black',
  children,
  promotion_price,
  has_tooltip,
  has_subscription,
  onSubscribeButtonClick,
}) => {
  const { t } = useTranslation('partner_plus');
  const { is_desktop } = useMediaQuery();
  const { info } = useInfo();
  const [{ isOpen, onClose, onOpen }] = useModalState();
  const is_show_scroll = useScrollDebounce(!!has_tooltip);
  const has_coupon = !!issued_coupon;
  const is_free_trial = has_coupon && issued_coupon.policy.type === 'FREE_TRIAL';

  return (
    <>
      <Tooltip
        zIndex={107}
        kind='accent'
        content={
          is_free_trial ? (
            t('무료체험 쿠폰 만료까지 {{date}}일 남았어요.', {
              date: dayjs().diff(issued_coupon.date_expired, 'd') * -1,
            })
          ) : promotion_price ? (
            t('{{promotion}}을 확인하세요', {
              promotion: promotion_price.promotion_name,
            })
          ) : (
            <></>
          )
        }
        openerTriggerEvent='none'
        canClickOutside={false}
        opened={is_show_scroll && has_tooltip && !has_subscription && (is_free_trial || !!promotion_price)}
        placement='bottom'
      >
        <Button
          fill={fill}
          disabled={!!info?.shop_subscription_resource.name}
          kind={button_kind}
          size={is_desktop ? 'large' : 'medium'}
          onClick={is_free_trial ? onOpen : onSubscribeButtonClick}
        >
          {children ? children : is_free_trial ? t('무료체험 신청하기') : t('파트너플러스 구독 신청하기')}
        </Button>
      </Tooltip>
      {issued_coupon && (
        <CouponSubscriptionModal
          isOpen={isOpen}
          onClose={onClose}
          issued_coupon={issued_coupon}
          item_id={'16'}
          product_id={SUBSCRIPTION_PRODUCT_ID.PARTNER_PLUS}
        />
      )}
    </>
  );
};

export default IntroSubscribeButton;
