import { HStack, HStackProps } from '@croquiscom/pds';
import React, { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import { ResponsiveStackType } from './type';
import { getReponsiveValue } from './utils';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsiveHStackProps
  extends Omit<HStackProps, 'spacing' | 'p' | 'm' | 'width' | 'height'>,
    ResponsiveStackType<HStackProps>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
}

const ResponsiveHStack = forwardRef<HTMLDivElement, ResponsiveHStackProps>(
  ({ spacing, p, m, height, width, children, ...props }, ref) => {
    const { is_desktop } = useMediaQuery();

    return (
      <HStack
        ref={ref}
        spacing={getReponsiveValue(spacing, is_desktop)}
        m={getReponsiveValue(m, is_desktop)}
        p={getReponsiveValue(p, is_desktop)}
        width={getReponsiveValue(width, is_desktop)}
        height={getReponsiveValue(height, is_desktop)}
        {...props}
      >
        {children}
      </HStack>
    );
  },
);
export default ResponsiveHStack;
