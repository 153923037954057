import {
  Alert,
  BaseText,
  HStack,
  HelpText,
  IconCircleQuestion,
  Popover,
  VStack,
  semantic_colors,
} from '@croquiscom/pds';
import React, { ReactNode } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

interface HelperTextProps {
  text: ReactNode;
  helper_title?: string;
  content?: ReactNode;
  size?: 'small' | 'large';
  color?: string;
}

const HelperText: React.FC<HelperTextProps> = ({ text, content, helper_title, size = 'small', color }) => {
  const { is_desktop } = useMediaQuery();
  return (
    <HStack alignment='center' spacing={size === 'small' ? 2 : 4}>
      {typeof text === 'string' ? (
        <BaseText kind='Body_13_Medium' color={color}>
          {text}
        </BaseText>
      ) : (
        text
      )}
      {is_desktop ? (
        <Popover placement='top' content={<HelpText contents={[{ text: content }]} title={helper_title || text} />}>
          <VStack alignment='center'>
            <IconCircleQuestion color={semantic_colors.content.tertiary} />
          </VStack>
        </Popover>
      ) : (
        <VStack
          alignment='center'
          onClick={() => {
            Alert({
              title: helper_title || text,
              text: content,
            });
          }}
        >
          <IconCircleQuestion color={semantic_colors.content.tertiary} />
        </VStack>
      )}
    </HStack>
  );
};

export default HelperText;
