import { IconCircleInfo, TextButton } from '@croquiscom/pds';
import React from 'react';

const GUIDE_URL =
  'https://docs.google.com/presentation/d/1HtGGz_QjpH9nXHE6UgQWL7360OieGw1purviAvoJv1E/edit#slide=id.g2768667bb0d_0_3964';

export const ShippingFeePolicyGuideButton: React.FC = () => {
  return (
    <TextButton
      kind='link'
      startIcon={<IconCircleInfo width={16} height={16} />}
      size='large'
      onClick={(e) => {
        e.preventDefault();
        window.open(GUIDE_URL, '_blank', 'noopener');
      }}
    >
      배송/반품/교환 비용 정책
    </TextButton>
  );
};
