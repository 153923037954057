import { colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';

const Card = styled.div<{ radius?: number }>`
  margin: 20px;
  background: ${colors.white};
  border: 1px solid ${semantic_colors.border.primary};
  border-radius: ${({ radius }) => (typeof radius === 'number' ? `${radius}px` : 'none')};
`;

export default Card;
