import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useAccount } from '@/hooks/useAccount';
import { useQueryParams } from '@/hooks/useQueryParams';
import { api_getCustomerExpansionStateOfShop } from 'api';

export function useGetCustomerExpansionStateOfShop(enabled?: boolean) {
  const query = useQueryParams();
  const { is_alpha, is_dev, is_beta } = useAccount();
  const is_test = is_alpha || is_dev || is_beta;
  const date = query.get('base_date') && is_test ? dayjs(query.get('base_date'), 'YYYYMMDDHH').valueOf() : undefined;
  return useQuery(
    ['useGetCustomerExpansionStateOfShop'],
    async () => {
      const { data } = await api_getCustomerExpansionStateOfShop({ date }, { no_alert: true });
      return data.getCustomerExpansionStateOfShop;
    },
    {
      enabled,
      staleTime: 30000,
      cacheTime: 30000,
      onError() {},
    },
  );
}
