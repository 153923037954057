import { BaseText, colors, HStack } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CatalogDetailCrawlingTitle: FC = () => {
  const { t } = useTranslation('catalog');
  return (
    <HStack spacing={6} alignment='center'>
      <div>{t('상품 수정')}</div>
      <BaseText kind='Heading_18_SemiBold' color={colors.gray600}>
        | {t('연동형')}
      </BaseText>
    </HStack>
  );
};

export default CatalogDetailCrawlingTitle;
