import { colors } from '@croquiscom/pds';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { card } from '@/components/ad/common/styles';

const placeholder = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

interface Props {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  radius?: number;
}

export const Root = styled.div<Props>`
  ${({ radius = 8 }) => card({ color: colors.gray10, radius })}
  position: relative;
  overflow: hidden;
  width: ${({ width = 'auto' }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height = 'auto' }) => (typeof height === 'number' ? `${height}px` : height)};

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(253, 253, 253, 0) 31%,
      rgba(253, 253, 253, 0.9) 50%,
      rgba(253, 253, 253, 0) 69%
    );
    transform: translateX(-100%);
    animation: ${placeholder} 4s ease-in-out infinite;
  }
`;
