import { VStack, HStack, semantic_colors, shapes, IconArrowChevronLeft, IconArrowChevronRight } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { useState, useRef } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Example_1 from '@/assets/images/promotion/price_optimization/example_1.png';
import Example_2 from '@/assets/images/promotion/price_optimization/example_2.png';
import Example_3 from '@/assets/images/promotion/price_optimization/example_3.png';
import ResponsiveHStack from '@/components/common/responsive/ResponsiveHStack';
import ResponsiveText from '@/components/common/responsive/ResponsiveText';
import ResponsiveVStack from '@/components/common/responsive/ResponsiveVStack';

const ExampleSwiper: React.FC = () => {
  const review_ref = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current_slide, setCurrentSlide] = useState<number>(0);
  const LIST = [
    {
      text: '검색',
      image: Example_1,
    },
    {
      text: '카테고리 메뉴',
      image: Example_2,
    },
    {
      text: '상품 상세페이지 추천',
      image: Example_3,
    },
  ];

  return (
    <ResponsiveVStack spacing={{ desktop: 32, mobile: 24 }} alignment='center'>
      <SC.ButtonTabs>
        {Array.from({ length: 3 }).map((_, index) => (
          <SC.ButtonTab key={index} active={current_slide === index} onClick={() => swiper?.slideTo(index)}>
            {current_slide === index && <SC.ButtonTabBg layoutId='activeTab' transition={{ duration: 0.3 }} />}
            <SC.ButtonTabText
              animate={{
                color:
                  current_slide === index ? semantic_colors.content.primary_inverse : semantic_colors.content.secondary,
              }}
              transition={{ duration: 0.3 }}
            >
              <ResponsiveText kind={{ desktop: 'Heading_15_SemiBold', mobile: 'Body_14_SemiBold' }}>
                {LIST[index].text}
              </ResponsiveText>
            </SC.ButtonTabText>
            <ResponsiveText
              kind={{ desktop: 'Heading_15_SemiBold', mobile: 'Body_14_SemiBold' }}
              color={semantic_colors.content.primary_inverse}
            >
              {LIST[index].text}
            </ResponsiveText>
          </SC.ButtonTab>
        ))}
      </SC.ButtonTabs>
      <VStack>
        <ResponsiveHStack width='100%' alignment='center' spacing={{ desktop: 48, mobile: 8 }}>
          <SC.PaginationButton onClick={() => swiper?.slidePrev()}>
            <IconArrowChevronLeft size={32} color={semantic_colors.content.secondary} />
          </SC.PaginationButton>
          <SC.CarouselContainer width={258} ref={review_ref}>
            <Swiper
              onSwiper={(swiper) => setSwiper(swiper)}
              onSlideChange={(e) => setCurrentSlide(e.realIndex)}
              slidesPerView={1}
              loop
            >
              {LIST.map((item, index) => (
                <SwiperSlide key={index}>
                  <ResponsiveVStack width={{ mobile: '100%' }} spacing={{ mobile: 10 }} alignment='center'>
                    <SC.Image src={item.image} alt='' />
                  </ResponsiveVStack>
                </SwiperSlide>
              ))}
            </Swiper>
          </SC.CarouselContainer>
          <SC.PaginationButton onClick={() => swiper?.slideNext()}>
            <IconArrowChevronRight size={32} color={semantic_colors.content.secondary} />
          </SC.PaginationButton>
        </ResponsiveHStack>
      </VStack>
    </ResponsiveVStack>
  );
};

const SC = {
  CarouselContainer: styled(HStack)`
    position: relative;
    height: 400px;
    text-align: left;
  `,
  Image: styled.img`
    width: 100%;
  `,
  ButtonTabs: styled(HStack)`
    padding: 8px;
    gap: 2px;
    ${shapes.border_radius.medium};
    border: 1px solid ${semantic_colors.border.primary};
    background-color: ${semantic_colors.background.surface};
  `,
  ButtonTab: styled(HStack)<{ active: boolean }>`
    padding: 6px 12px;
    ${shapes.border_radius.small};
    position: relative;
    align-items: center;
    justify-content: center;
    ${({ active }) =>
      active ? `color: ${semantic_colors.content.primary_inverse};` : `color: ${semantic_colors.content.secondary};`}
    cursor: pointer;
  `,
  ButtonTabText: styled(motion.div)`
    position: absolute;
  `,
  ButtonTabBg: styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 6px 12px;
    ${shapes.border_radius.small};
    background-color: ${semantic_colors.accent.primary};
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  `,
  PaginationButton: styled(HStack)`
    cursor: pointer;
  `,
};

export default ExampleSwiper;
