import { colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';

import Dropdown, { DropdownProps } from 'pastel/Dropdown';
import { defaultLine } from 'styles';

interface SelectOptions {
  id: string;
  text: React.ReactNode;
}
interface SelectProps extends DropdownProps {
  options: SelectOptions[];
  onSelect: (string) => void;
}

/**
 * @deprecated 'rui/pastel/unstable/Select'를 사용해주세요.
 */
const Select: React.FC<SelectProps> = ({ className, value, options, isOpen, onSelect, onOpen, disabled }) => {
  return (
    <Dropdown
      className={className}
      closeOnContentClick
      value={value}
      isOpen={isOpen}
      onOpen={onOpen}
      disabled={disabled}
      content={
        <StyledOptions>
          {options.map(({ id, text }) => (
            <div
              key={id}
              className='select-options__option'
              onClick={() => {
                onSelect(id);
              }}
            >
              {text}
            </div>
          ))}
        </StyledOptions>
      }
    />
  );
};

const StyledOptions = styled.div`
  border: ${defaultLine};
  .select-options__option {
    border-bottom: ${defaultLine};
    background: ${colors.white};
    cursor: pointer;
  }
  .select-options__option:last-of-type {
    border-bottom: none;
  }

  .select-options__option:hover {
    background: ${colors.gray10};
  }
`;

export default Select;
