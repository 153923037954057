import {
  BaseText,
  Button,
  NoticeModal,
  VStack,
  IconArrowDirectionRight,
  colors,
  semantic_colors,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useNoticeModalState } from '../hooks/useNoticeModalState';
import { useGetWmsShopAgreementQuery } from '@/api';
import { Config } from '@/config';
import { useInfo } from '@/hooks/useInfo';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import zigzin_outlet_promotion_content from 'assets/images/zigzin/zigzin_outlet_promotion_content.png';
import zigzin_outlet_promotion_quick_icon from 'assets/images/zigzin/zigzin_outlet_promotion_quick_icon.svg';
import { WarehousedInUrl } from 'rui/components/URL';

const ZigzinOutletPromotionPopup: FC = () => {
  const { t } = useTranslation(['common', 'zigzin']);
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { is_zigzin } = useInfo();
  const { data, isLoading } = useGetWmsShopAgreementQuery();

  const IS_EXPOSURE_PERIOD = dayjs().isBetween('2023-09-20 00:00:00', '2023-10-20 23:59:59');
  const LOCAL_STORAGE_CODE = `ZigzinOutletPromotionPopup.${Config.shop_main_domain}.LastConfirmed`;
  const { is_open, replay_yn, onClose, onChangeCheckReplay } = useNoticeModalState({
    key: LOCAL_STORAGE_CODE,
    replayFunction: () => {
      const last_confirm_date = localStorage.getItem(LOCAL_STORAGE_CODE);
      return IS_EXPOSURE_PERIOD && !dayjs(last_confirm_date).isToday();
    },
  });

  if (!is_zigzin || isLoading || !!data?.wms_shop_agreement.id) {
    return null;
  }

  const goToPromotionPage = () => {
    history.push(`${shop_prefix}${WarehousedInUrl.ZIGZIN_OUTLET_PROMOTION}`);
  };

  return (
    <NoticeModal opened={is_open} checkReplay={replay_yn} onCancel={onClose} onChangeCheckReplay={onChangeCheckReplay}>
      <ContentWrap spacing={20}>
        <VStack spacing={10} alignment='center'>
          <img width={60} src={zigzin_outlet_promotion_quick_icon} alt='zigzin_outlet_promotion_quick_icon' />
          <BaseText as='h2' kind='Heading_24_Bold' color={semantic_colors.content.primary} alignment='center'>
            {t('zigzin::직진아울렛 신규 오픈')}
          </BaseText>
          <BaseText as='p' kind='Heading_17_SemiBold' color={semantic_colors.content.secondary} alignment='center'>
            <Trans ns='zigzin'>
              판매저조 상품에 할인을 적용하세요.
              <br />
              직진아울렛에 노출되어 매출을 올릴 수 있어요!
            </Trans>
          </BaseText>
        </VStack>

        <img src={zigzin_outlet_promotion_content} alt='zigzin_outlet_promotion_content' />

        <VStack alignment='center'>
          <Button
            kind='primary'
            size='large'
            endIcon={<IconArrowDirectionRight size={20} color={semantic_colors.content.primary_inverse} />}
            onClick={goToPromotionPage}
          >
            {t('zigzin::직진아울렛 참여하기')}
          </Button>
        </VStack>
      </ContentWrap>
    </NoticeModal>
  );
};

export default ZigzinOutletPromotionPopup;

const ContentWrap = styled(VStack)`
  padding: 32px 30px;
  background-color: ${colors.blue50};
  border-radius: 16px 16px 0 0;
`;
