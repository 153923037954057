import React, { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import ResponsiveHStack from './ResponsiveHStack';
import ResponsiveVStack, { ResponsiveVStackProps } from './ResponsiveVStack';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ResponsiveFlexProps
  extends Omit<ResponsiveVStackProps, 'alignment'>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  origin_direction?: 'row' | 'column';
  alignment?: 'center';
  children: React.ReactNode;
}

const ResponsiveFlex = forwardRef<HTMLDivElement, ResponsiveFlexProps>(({ origin_direction, ...props }, ref) => {
  const { is_desktop } = useMediaQuery();
  if (origin_direction === 'column') {
    return is_desktop ? <ResponsiveVStack ref={ref} {...props} /> : <ResponsiveHStack ref={ref} {...props} />;
  }
  return is_desktop ? <ResponsiveHStack ref={ref} {...props} /> : <ResponsiveVStack ref={ref} {...props} />;
});

export default ResponsiveFlex;
