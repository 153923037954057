import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export const ZIGZIN_GUIDE_OPTIONS = createHelpConstantOptions({
  name: '직진배송 가이드 URL',
  defaults: {
    '운영 가이드':
      'https://www.notion.so/croquis/88f11f38e607488190560f4e60eb2827?pvs=4#0968cc006e784ff5bbdcfbe8135f26f9',
    '상품 포장 가이드': 'https://www.notion.so/croquis/2-bcbc00dea26b4ba589d8e4bd3a34f8cd?pvs=4',
    '판매 제한 상품 관리': 'https://www.notion.so/croquis/5-44b4f763cfb24963a6e6fcec053afae8',
    '서비스 소개서':
      'https://www.notion.so/croquis/9bae3f0221d84578a17882635a862388?v=1758f4d8dd5b4fb6b9d2770abc562908&pvs=4',
    '물류비 정산기준': 'https://docs.google.com/document/d/1S0A92CDKuRbrJOFupnpYVysO2qnvNTAOyHAncjA19Aw/edit',
    '직진아울렛 가이드': 'https://croquis.notion.site/2626fa9f8a094109908c9a4bb9a01bfe',
    '직진배송 셀러 사례': 'https://partnerlounge.kakaostyle.com/insight/9xr9harrnrhk38td7zetrb6y5gdl4w',
    '직진배송 상품등록 방법': 'https://partnerlounge.kakaostyle.com/insight/rsycbt58kpsk5t8a6xgsl5zz7f3527-r5tgk',
    '직진배송 상품 등록 영상 가이드(embed)': 'https://www.youtube.com/embed/EBSrq3fAPYI',
    '상품 카테고리별 입출고 유통기한': 'https://croquis.notion.site/47f11e2374494089a051105275c059c4',
    '엑셀 대량 입고':
      'https://docs.google.com/presentation/d/1ZNyRgltSRO2bogM6zX9YbvuUcnFQ7pELL5F5ajvet_Q/edit#slide=id.g20a1c66b1e3_0_498',
    '직진배송 입고 정책': 'https://www.notion.so/croquis/56824b3ae5824ef8b9d3a7c361c56425',
    '입고 신청 영상 가이드(embed)': 'https://www.youtube.com/embed/fsy1olmJYro',
    '입고 신청 영상 가이드': 'https://youtu.be/fsy1olmJYro',
    '입고 신청 영상 가이드 - 기본 정보 선택': 'https://youtu.be/fsy1olmJYro?t=80',
    '입고 신청 영상 가이드 - 입고 품목 선택': 'https://youtu.be/fsy1olmJYro?t=161',
    '입고 신청 영상 가이드 - 발송 정보 입력': 'https://youtu.be/fsy1olmJYro?t=405',
    '입고 신청 영상 가이드 - 최종확인': 'https://youtu.be/fsy1olmJYro?t=505',
    '입고 현황 영상 가이드': 'https://youtu.be/fsy1olmJYro?t=637',
  },
});

export const BEAUTY_BARCODE_GUIDE =
  'https://www.law.go.kr/%ED%96%89%EC%A0%95%EA%B7%9C%EC%B9%99/%ED%99%94%EC%9E%A5%ED%92%88%20%EB%B0%94%EC%BD%94%EB%93%9C%20%ED%91%9C%EC%8B%9C%20%EB%B0%8F%20%EA%B4%80%EB%A6%AC%EC%9A%94%EB%A0%B9';

export const ZIGZIN_CENTER_NAMES = {
  직진1센터: '직진 1센터', // 구 이천 센터
  직진2센터: '직진 2센터', // 구 덕평 센터
};
