import { colors, IconArrowTriangleDown } from '@croquiscom/pds';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Options as PopperJSOptions } from '@popperjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import useClickOutside from 'rui/hooks/useClickOutside';

export interface DropdownProps {
  className?: string;
  isOpen?: boolean;
  value: React.ReactNode;
  content?: React.ReactNode;
  popperOptions?: PopperJSOptions;
  selectedStyle?: SerializedStyles;
  contentStyle?: SerializedStyles;
  closeOnContentClick?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  disabled?: boolean;
}

/**
 * @deprecated 파트너센터의 {@link [components/pdstop/Dropdown](http://localhost:7001/?path=/story/components-pdstop-dropdown-dropdown--base&globals=locale:ko)} 컴포넌트를 사용해주세요.
 */
const Dropdown: React.FC<DropdownProps> = ({
  className,
  isOpen,
  value,
  content,
  popperOptions = {},
  closeOnContentClick,
  selectedStyle,
  contentStyle,
  onOpen,
  onClose,
  disabled,
}) => {
  const [opened, setOpened] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLButtonElement>(null);

  useClickOutside([dropdownRef, { current: popperElement }], opened, () => {
    setOpened(false);
  });

  useEffect(() => {
    if (isOpen !== opened) {
      setOpened(!!isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    const onWindowScroll = () => {
      setOpened(false);
    };
    if (opened) {
      onOpen && onOpen();
      window.addEventListener('scroll', onWindowScroll, true);
    } else {
      onClose && onClose();
      window.removeEventListener('scroll', onWindowScroll);
    }
  }, [opened]);

  const onClickSelected = () => {
    if (disabled) {
      return;
    }
    setOpened(!opened);
  };

  const onClickContent = () => {
    if (closeOnContentClick) {
      setOpened(!opened);
    }
  };

  const { styles, attributes } = usePopper(popperRef.current, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    ...popperOptions,
  });

  return (
    <StyledDropdown className={className} opened={opened} ref={dropdownRef}>
      <SelectedDropdown
        type='button'
        disabled={disabled}
        className='dropdown__selected'
        selectedStyle={selectedStyle}
        onClick={onClickSelected}
        ref={popperRef}
      >
        {value}
        {opened ? <RotatSelectArrow /> : <IconArrowTriangleDown size={16} />}
      </SelectedDropdown>
      {opened &&
        createPortal(
          <StyledTooltip
            ref={setPopperElement}
            style={{ ...styles.popper, ...{ zIndex: 1000 } }}
            {...attributes.popper}
            contentStyle={contentStyle}
            onClick={onClickContent}
          >
            {content}
          </StyledTooltip>,
          document.body,
        )}
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div<{ opened: boolean }>`
  position: relative;
  border: solid 1px ${({ opened }) => (opened ? colors.gray800 : colors.gray30)};
  font-size: 13px;
  letter-spacing: -0.3px;
  color: ${colors.gray800};
  cursor: pointer;
`;

const SelectedDropdown = styled.button<{ selectedStyle?: SerializedStyles }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  > svg {
    margin: 0 7px 0 4px;
    pointer-events: none;
  }

  ${({ selectedStyle }) => selectedStyle || ''};

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledTooltip = styled.div<{ contentStyle?: SerializedStyles }>`
  border: solid 1px ${colors.gray800};
  background: ${colors.white};
  z-index: 1;
  cursor: auto;
  ${({ contentStyle }) => contentStyle || ''};
`;
const RotatSelectArrow = styled(IconArrowTriangleDown)`
  transform: rotate(180deg);
`;

export default Dropdown;
