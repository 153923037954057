import { t } from 'i18next';
import {
  checkRes,
  isAllowedByShopDepartment,
  isAllowedMenuByCountry,
  isAllowedMenuBySiteId,
  isNotAllowedByShopDepartment,
} from '../../utils/menu_list';
import { SubMenuEntry } from '../types';
import { CreateSubMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment } from '@/types/info';
import { CountryCode } from 'api';

export function createSubMenuTree({ info, has_new_notice }: CreateSubMenuTreeOptions): SubMenuEntry[] {
  return [
    {
      id: 'notice',
      label: t('main_frame::공지사항'),
      href: '/notice',
      is_allowed:
        checkRes([], info.allowed_resource_list) &&
        isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.도매, SiteId.마이스토어]) &&
        isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
      is_new: has_new_notice,
      is_support_app: true,
    },
    {
      id: 'seller_support_program',
      label: t('main_frame::중소형 성장 지원 프로그램'),
      href: '/seller_support_program',
      is_allowed:
        isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
        isAllowedMenuByCountry(info, [CountryCode.KR]) &&
        isAllowedByShopDepartment(info, [ShopDepartment.SOHO_FASHION]),
    },
  ];
}
