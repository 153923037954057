import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from 'api';

const ShopContractPage = lazy(() => import('@/pages/shop_contract/contract/ShopContractPage'));
const ShopInternalContractPage = lazy(() => import('@/pages/shop_contract/internal_contract/ShopInternalContractPage'));
const ShopCouponContractPage = lazy(() => import('@/pages/shop_contract/internal_contract/ShopCouponContractPage'));
const ProductCouponContractPage = lazy(
  () => import('@/pages/shop_contract/internal_contract/ProductCouponContractPage'),
);

export const SHOP_CONTRACT_ROUTES: RoutesType[] = [
  {
    current_menu: 'shop_contract',
    path: '/shop_contract',
    params: '',
    component: (
      <>
        <ShopContractPage />
      </>
    ),
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'shop_contract',
    path: '/shop_internal_contract',
    params: '',
    component: <ShopInternalContractPage />,
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'shop_contract',
    path: '/shop_coupon_contract',
    params: '',
    component: <ShopCouponContractPage />,
    res: [SellerResource.ALL],
  },
  {
    current_menu: 'shop_contract',
    path: '/product_coupon_contract',
    params: '',
    component: <ProductCouponContractPage />,
    res: [SellerResource.ALL],
  },
];
