import { BaseText, colors, IconArrowChevronRight, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { adPromotionShopListSet } from './adPromotionShoplist';
import { Button } from '@/components/pdstop';
import { Config } from '@/config';
import { useInfo } from '@/hooks/useInfo';
import Checkbox from 'pastel/Checkbox';
import Modal from 'rui/pastel/Modal';

const today = dayjs().format('YYYYMMDD');
const PROMOTION_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfOXdKC-27AM_98Xqy9snzQHua8zMFXjLuAaOwFg3rf9D8vng/viewform?usp=sharing';

const AdPromotionPopup = () => {
  const LOCAL_STORAGE_CODE = `AdPromotionPopup.${Config.shop_main_domain}.LastConfirmed`;
  const { info, is_zigzag } = useInfo();
  const [isOpen, setIsOpen] = useState<boolean>(
    is_zigzag &&
      localStorage.getItem(LOCAL_STORAGE_CODE) !== today &&
      dayjs().isBetween('2023-02-22 00:00:00', '2023-02-28 23:59:59') &&
      !!info.id &&
      adPromotionShopListSet.has(info.id),
  );
  const [replayYn, setReplayYn] = useState<boolean>(false);

  const handleCheckboxClick = (checked: boolean) => {
    setReplayYn(checked);
    if (checked) {
      localStorage.setItem(LOCAL_STORAGE_CODE, today);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CODE);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handlePromotionClick = () => {
    handleClose();
    window.open(PROMOTION_FORM_LINK, '_blank', 'noreferrer');
  };

  return (
    <StyledModal isOpen={isOpen} width={500} onClose={handleClose}>
      <Header>
        <Title>공지사항</Title>
        <Heading>광고 미션 달성 프로모션</Heading>
      </Header>
      <Content>
        <BaseText kind='Body_14_SemiBold'>무료 광고 컨설팅만 신청해도 무상코인 3만원 지급!</BaseText>
        <BaseText kind='Body_14_Regular' as='div' style={{ marginTop: '20px' }}>
          1. 무료 광고 컨설팅만 신청해도 <BaseText kind='Body_14_Bold'>무상코인 3만원</BaseText> 지급 <br />
          2. 개별 미션 완료 시 <BaseText kind='Body_14_Bold'>무상코인 최대 7만원</BaseText> 지급 <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • &nbsp; 1일 이상 광고 집행 (2만원 지급) <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • &nbsp; 광고자료 확인하고 퀴즈 풀기 (1~3만원 지급) <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; • &nbsp; 광고비 충전 (2만원 지급)
        </BaseText>
        <BaseText kind='Body_14_Regular' as='div' style={{ marginTop: '20px' }}>
          • &nbsp; 컨설팅 신청한 셀러들만 2번 미션 지원자격 부여 <br />
          • &nbsp; 컨설팅 신청 이후 14일 이내 까지만 2번 미션 조건 적용 <br />
          • &nbsp; '1일 이상 광고 집행 시’ 미션은 무상코인 집행도 조건 달성 허용
          <br />• &nbsp; ’광고비 충전’ 미션은 이미 잔여 광고비가 있어도 추가 충전 시 조건 달성'
        </BaseText>
        <BaseText kind='Body_14_Regular' as='div' style={{ marginTop: '20px' }}>
          접수기간 : 23년 2월 28일까지
        </BaseText>
        <StyledButton kind='black' onClick={handlePromotionClick}>
          컨설팅 신청하기 <IconArrowChevronRight color={colors.white} />
        </StyledButton>
        <Divider />
      </Content>
      <Footer>
        <Checkbox checked={replayYn} onChange={(e) => handleCheckboxClick(e.currentTarget.checked)}>
          <Replay>오늘 하루 안보기</Replay>
        </Checkbox>
        <CloseButton kind='outlined_white' onClick={handleClose}>
          닫기
        </CloseButton>
      </Footer>
    </StyledModal>
  );
};

export default AdPromotionPopup;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
  }
`;

const Header = styled.div`
  padding: 20px 30px;
  border-radius: 6px 6px 0 0;
`;

const Title = styled.div`
  color: #ff349c;
  ${text_styles.Body_13_Bold};
`;

const Heading = styled.h1`
  margin-top: 10px;
  ${text_styles.Heading_24_Bold};
`;

const Content = styled.div`
  padding: 30px 30px 0 30px;
  ${text_styles.Body_14_Regular};
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const Divider = styled.div`
  margin-top: 30px;
  border: 0.5px solid #e7e7ed;
`;

const Footer = styled.div`
  background: white;
  padding: 10px 30px 20px;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseButton = styled(Button)`
  ${text_styles.Body_13_Regular};
  color: ${colors.gray800};
  border: none;
`;

const Replay = styled.span`
  ${text_styles.Body_13_Regular};
  color: ${colors.gray800};
`;
