import { colors, semantic_colors } from '@croquiscom/pds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { Card } from 'rui/pastel/unstable/card';

export const CardWithMinWidth = styled(Card)`
  min-width: 1030px;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  height: 600px;
  border: 1px solid ${colors.gray30};
  margin-bottom: 10px;

  table {
    thead th {
      border-top: none;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      border-left: none;
    }
  }
`;

export const TableHeader = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background-color: ${colors.gray10};
  border: 1px solid ${colors.gray30};
  border-bottom: 0;
`;

export const ModalContainer = styled.div`
  max-height: 100vh;
  overflow: scroll;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 28px;
  border: 1px solid ${colors.gray30};
`;

export const ModalBody = styled.div`
  max-height: calc(100vh - 155px);
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 28px;
  border-top: 1px solid ${colors.gray30};
`;

interface EmphasisProps extends HTMLAttributes<HTMLElement> {
  /** @default semantic_colors.accent.primary (blue600) */
  color?: string;
  /** 600 */
  bold?: boolean;
}
export const Emphasis = styled.em<EmphasisProps>`
  font-style: normal;
  ${({ bold }) => bold && `font-weight: 600;`}
  color: ${({ color }) => color ?? semantic_colors.accent.primary};
`;

export const show_scroll_bar = css`
  &::-webkit-scrollbar {
    width: 17px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 40px;
    background-color: ${colors.gray30};
  }
`;

export const CustomScrollContainer = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height ?? '100%'};
  overflow: auto;

  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: ${colors.gray30};
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const LinkText = styled.a`
  color: ${colors.blue500};
  text-decoration: none;
`;
