import React, { lazy } from 'react';
import { Trans } from 'react-i18next';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { GUIDE_ORDER_ITEM } from '@/components/common/guide/constants';
import TransPlainText from '@/components/common/TransPlainText';
import OrderItemListPageHeader from '@/components/order_item/list/common/OrderItemListPageHeader';
import { OrderItemListUrl } from 'rui/components/URL';

const OrderItemListCancelPage = lazy(() => import('@/pages/order_item/list/cancel/OrderItemListCancelPage'));
const OrderItemListConfirmPage = lazy(() => import('@/pages/order_item/list/confirm/OrderItemListConfirmPage'));
const OrderItemListExchangePage = lazy(() => import('@/pages/order_item/list/exchange/OrderItemListExchangePage'));
const OrderItemListPendingPage = lazy(() => import('@/pages/order_item/list/pending'));
const OrderItemListPreShipmentPage = lazy(
  () => import('@/pages/order_item/list/pre_shipment/OrderListPreShipmentPage'),
);
const OrderItemListReturnPage = lazy(() => import('@/pages/order_item/list/return/OrderItemListReturnPage'));
const OrderItemListSearchPage = lazy(() => import('@/pages/order_item/list/search'));
const OrderItemListShipmentPage = lazy(() => import('@/pages/order_item/list/shipment/OrderItemListShipmentPage'));

export const ORDER_ROUTES: RoutesType[] = [
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_search',
    path: OrderItemListUrl.SEARCH,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='전체 주문 검색' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
        />
        <OrderItemListSearchPage />
      </>
    ),
    res: [SellerResource.ZPAY_ORDER_SEARCH],
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_pre_shipment',
    path: OrderItemListUrl.PRE_SHIPMENT,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='상품준비/배송 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
          text_button={GUIDE_ORDER_ITEM.PRE_SHIPMENT}
        />
        <OrderItemListPreShipmentPage />
      </>
    ),
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_pending',
    path: OrderItemListUrl.PENDING,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='대기 주문' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 판매자가 발송처리를 시작할 수 있는 [주문확인] 전 단계의 주문을 조회할 수 있습니다.
              <br />
              - 일반 주문은 [결제완료] 상태에서 후 약 15분 경과 뒤 [주문확인]으로 이동합니다.
              <br />
              - [입금대기]는 무통장입금으로 주문 후 입금이 완료되면 [결제완료]로 이동합니다. 단, 3일동안 입금이 되지
              않으면 자동으로 취소됩니다.
              <br />- 프리오더 주문의 경우, 결제완료 상태에서 대기하며 [발송예정일]을 기다리며 해당일자에 [주문확인]
              으로 자동으로 이동합니다.
            </Trans>
          }
        />
        <OrderItemListPendingPage />
      </>
    ),
    res: [SellerResource.ZPAY_ORDER_SEARCH],
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_shipment',
    path: OrderItemListUrl.SHIPMENT,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='배송현황 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
          text_button={GUIDE_ORDER_ITEM.SHIPMENT}
        />
        <OrderItemListShipmentPage />
      </>
    ),
    res: [SellerResource.ZPAY_ORDER_MANAGEMENT],
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_confirm',
    path: OrderItemListUrl.CONFIRM,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='구매확정 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
        />
        <OrderItemListConfirmPage />
      </>
    ),
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_cancel',
    path: OrderItemListUrl.CANCEL,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='취소 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
          text_button={GUIDE_ORDER_ITEM.CANCEL}
        />
        <OrderItemListCancelPage />
      </>
    ),
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_return',
    path: OrderItemListUrl.RETURN,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='반품 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
          text_button={GUIDE_ORDER_ITEM.RETURN}
        />
        <OrderItemListReturnPage />
      </>
    ),
  },
  {
    is_support_app: true,
    is_support_mobile: true,
    current_menu: 'order_item_list_exchange',
    path: OrderItemListUrl.EXCHANGE,
    params: '',
    component: (
      <>
        <OrderItemListPageHeader
          title={<TransPlainText i18nKey='교환 관리' ns='order_item' />}
          description={
            <Trans ns='order_item'>
              - 카카오스타일 채널에서 [Z결제]로 결제된 주문 내역을 조회/관리할 수 있는 메뉴입니다.
              <br />- 구매자에게 카카오스타일 고객센터 안내 시, 반드시 구매자 전용 채널인 1670-8050으로 안내해주세요.
            </Trans>
          }
          text_button={GUIDE_ORDER_ITEM.EXCHANGE}
        />
        <OrderItemListExchangePage />
      </>
    ),
  },
];
