import { colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface NavItemProps extends React.HTMLAttributes<HTMLElement> {
  active?: boolean;
}

const NavItem: FC<NavItemProps> = ({ className, active, children, onClick }) => {
  return (
    <StyledNavItem className={className} active={active} onClick={(e) => onClick && onClick(e)}>
      {children}
    </StyledNavItem>
  );
};

export default NavItem;

const StyledNavItem = styled.li<{ active?: boolean }>`
  position: relative;
  margin: 0 0 0 24px;
  padding: 3px 0 0 0;
  height: 100%;
  color: ${({ active }) => (active ? colors.zigzag_pink500 : colors.gray800)};
  cursor: pointer;
  ${text_styles.Heading_15_SemiBold}
  ${({ active }) => active && `border-bottom: 3px solid ${colors.zigzag_pink500};`}

  &:hover {
    ${({ active }) => !active && `border-bottom: 3px solid ${colors.gray50};`}
  }

  & a {
    ${text_styles.Heading_15_SemiBold};
    color: ${colors.gray800};
  }
`;
