import { Tooltip, IconBell, Popover, TopBar, useToast, colors, semantic_colors, getZIndex } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AlertBoxModal } from './AlertBoxModal';
import { useGetAlertList } from './hooks/useGetAlertList';
import { useGetPartnerCenterNotificationCountQuery } from './hooks/useGetPartnerCenterNotificationCountQuery';
import { Config } from '@/config';
import { useOnceTooltip } from '@/hooks/useOnceTooltip';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export interface AlertBoxProps {
  is_mobile?: boolean;
}

const TOOLTIP_HAS_ALERT = `Gnb.${Config.shop_main_domain}.TooltipHasAlert`;

const AlertBox: React.FC<AlertBoxProps> = ({ is_mobile }) => {
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const toast = useToast();
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { data: items, error, refetch, updateDismiss } = useGetAlertList();
  const { data } = useGetPartnerCenterNotificationCountQuery();
  const [opened, setOpened] = useState(false);
  const [is_group_open, setGroupOpen] = useState(false);
  const { is_show, hideTooltip } = useOnceTooltip({
    key: TOOLTIP_HAS_ALERT,
  });
  const counts = {
    important: items?.length ?? 0,
    notification: data?.notification_count ?? 0,
    news: data?.news_count ?? 0,
    zigzin: data?.zigzin_count ?? 0,
  };
  const total_count = counts.important + counts.notification + counts.zigzin + counts.news;
  const has_alert = total_count > 0;

  const handleClick = () => {
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'notification_inbox',
      object_section: 'top_bar',
    });
    is_show && hideTooltip();
  };

  const openAlertBoxPopover = () => {
    handleClick();
    setOpened(true);
  };

  const moveToAlertBox = () => {
    handleClick();
    history.push(shop_prefix + '/alert_box');
  };

  const handleDismiss = (dismiss_key: string) => {
    updateDismiss(dismiss_key);
    toast.destroy('alert_item_ignore_notice');
    setTimeout(() => {
      toast.show({
        id: 'alert_item_ignore_notice',
        content: t('7일 후 다시 알려드릴게요'),
        position: 'bottom-center',
      });
    }, 1);
  };

  const renderButton = (onClick?: () => void) => (
    <StyledActionButton onClick={onClick}>
      <IconBell size={is_mobile ? 24 : 18} color={opened ? colors.blue400 : colors.white} />
      {has_alert && (
        <StyledTooltip
          openerTriggerEvent='none'
          canClickOutside={false}
          kind='accent'
          placement='bottom'
          opened={false}
          zIndex={getZIndex('notification')}
        >
          <SteyldNewIcon is_mobile={is_mobile}>{total_count > 100 ? '99+' : total_count}</SteyldNewIcon>
        </StyledTooltip>
      )}
    </StyledActionButton>
  );
  useEffect(() => {
    if (opened) {
      pbl({
        navigation: 'partnercenter_notification_inbox',
        category: 'pageview',
      });
    }
  }, [opened]);

  useEffect(() => {
    if (has_alert) {
      pbl({
        navigation: 'partnercenter_notification_inbox',
        category: 'impression',
        object_id: 'new_notification_badge',
        extra: JSON.stringify({ noticount: total_count }),
      });
    }
  }, [has_alert]);

  return is_mobile ? (
    renderButton(moveToAlertBox)
  ) : (
    <SteyldPopover
      arrow={false}
      padding={0}
      placement='bottom'
      opened={opened}
      onOpen={openAlertBoxPopover}
      onClose={() => setOpened(false)}
      content={({ close }) => (
        <AlertBoxModal
          counts={counts}
          items={items}
          error={error}
          refetch={refetch}
          close={close}
          dismiss={handleDismiss}
          is_group_open={is_group_open}
          onGroupOpen={() => setGroupOpen(true)}
          onGroupClose={() => setGroupOpen(false)}
        />
      )}
    >
      {renderButton()}
    </SteyldPopover>
  );
};

export default AlertBox;

const StyledActionButton = styled(TopBar.ActionButton)`
  position: relative;
  width: fit-content;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: -7px;
  margin-top: 16px;
`;

const SteyldNewIcon = styled.span<{
  is_mobile?: boolean;
}>`
  position: absolute;
  padding: 0 2px;
  min-width: 13px;
  height: 13px;
  border-radius: 8px;
  background-color: ${semantic_colors.graph.red};
  color: ${colors.white};
  font-size: 9px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  top: ${({ is_mobile }) => (is_mobile ? '-1px' : '-4px')};
  left: ${({ is_mobile }) => (is_mobile ? '12px' : '9px')};
`;

const SteyldPopover = styled(Popover)<{
  fullScreen?: boolean;
}>`
  background-color: ${colors.gray20};
  > div {
    height: 100%;
  }
  ${({ fullScreen }) =>
    fullScreen
      ? `
          margin: 0;
          width: 100%;
          height: 100%;
          position: fixed !important;
          top: 0 !important;
          left: 0 !important;
          border-radius: 0;
          max-height: 100vh;
          box-shadow: none;
        `
      : `
          margin-top: 16px;
          margin-left: -16px;
          max-height: calc(100vh - 100px);
          max-width: calc(100vw);
          width: 375px;
          height: 700px;
          border-radius: 16px;
          box-shadow: 0px 8px 30px 0px #0000004D;
      `};
`;
