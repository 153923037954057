import {
  BaseText,
  Button,
  Drawer,
  HStack,
  IconArrowChevronLeft,
  colors,
  semantic_colors,
  shapes,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useQueryClient } from 'react-query';
import { NOTIFICATION_2ND_CATEGORY_LABEL } from '../constants';
import { usePartnerCenterNotificationListQuery } from '../hooks/useGetNotificationList';
import { GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY } from '../hooks/useGetPartnerCenterNotificationCountQuery';
import { GroupInfo } from '../types';
import { NoAlertData } from './NoAlertData';
import { NotificationCard } from './NotificationCard';
import { SkeletonCardList } from './SkeletonCardList';
import { CardList, LoadingBox, ModalBody, ModalWrapper, ScrollArea } from './styles';
import { usePbl } from '@/hooks/usePbl';
import { useClickPartnerCenterNotificationByNotificationGroupIdMutation } from 'api';

export interface GroupCardListProps {
  opened: boolean;
  group_info?: GroupInfo;
  onClose: () => void;
  onCardClick: () => void;
  onReadAll?: () => void;
}

export const GroupCardList: React.FC<GroupCardListProps> = ({
  group_info,
  opened,
  onClose,
  onCardClick,
  onReadAll,
}) => {
  const { group_id, pbl_section, count } = group_info || {};
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const queryClient = useQueryClient();
  const { data, error, isLoading, isFetching, fetchNextPage, hasNextPage, refetch } =
    usePartnerCenterNotificationListQuery(
      { notification_group_id: group_id },
      {
        enabled: opened && Boolean(group_id),
        onError: () => {},
      },
    );
  const { mutate, isLoading: isMutating } = useClickPartnerCenterNotificationByNotificationGroupIdMutation({
    onSuccess: () => {
      refetch();
      onReadAll?.();
      queryClient.refetchQueries([GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY]);
    },
  });
  const items = data?.pages.flatMap(({ item_list }) => item_list).flat() || [];
  const total_count = data?.pages?.[0]?.total_count || 0;

  const handleAllRead = () => {
    if (!group_id) return;
    pbl({
      navigation: 'partnercenter_notification_inbox',
      object_id: 'grouped_message_mark_all',
      object_section: pbl_section,
      extra: JSON.stringify({
        message_title: items[0]?.title,
        message_category: NOTIFICATION_2ND_CATEGORY_LABEL[items[0]?.notification_2nd_category],
      }),
      category: 'click',
    });
    mutate({ input: { notification_group_id: group_id } });
  };

  return (
    <Box>
      <Drawer
        noUsePortal
        animationOnClose={false}
        opened={opened}
        onClose={onClose}
        width='100%'
        className='group-card-drawer'
      >
        <ModalWrapper>
          <ModalHeader>
            <BackButton onClick={onClose}>
              <IconArrowChevronLeft size={24} color='currentColor' />
            </BackButton>
            <BaseText kind='Heading_18_Bold' as='h1'>
              {t('총 {{ count }} 건', { count: isLoading ? count : total_count })}
            </BaseText>
            <Button
              onClick={handleAllRead}
              disabled={isLoading || !items.length}
              loading={isMutating}
              size='xsmall'
              style={{ marginLeft: 'auto' }}
            >
              {t('모두 읽음 처리')}
            </Button>
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <SkeletonCardList />
            ) : items.length ? (
              <ScrollArea>
                <InfiniteScroll
                  loadMore={() => !isFetching && fetchNextPage()}
                  hasMore={hasNextPage}
                  useWindow={false}
                  threshold={500}
                  loader={<LoadingBox key={0} />}
                >
                  <CardList>
                    {items?.map((item, index) => (
                      <NotificationCard
                        key={index}
                        item={item}
                        onClick={onCardClick}
                        no_group
                        pbl_section={pbl_section!}
                      />
                    ))}
                  </CardList>
                </InfiniteScroll>
              </ScrollArea>
            ) : (
              <ScrollArea nodata>
                <NoAlertData error={error} refetch={refetch} />
              </ScrollArea>
            )}
          </ModalBody>
        </ModalWrapper>
      </Drawer>
    </Box>
  );
};

const ModalHeader = styled(HStack)`
  justify-content: space-between;
  padding: 12px 16px;
  background-color: ${colors.white};
  column-gap: 10px;
  border-bottom: 1px solid ${semantic_colors.border.primary};
`;
const BackButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  ${shapes.border_radius.small};
  text-decoration: none;
  padding: 0;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.primary};
  }
`;
const Box = styled.div`
  div:has(> .group-card-drawer) {
    background-color: transparent !important;
  }
`;
