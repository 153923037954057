import { BaseText, colors, Divider, text_styles, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { PopupType } from './constants';
import { Button } from '@/components/pdstop';
import { useHistory } from '@/components/route/LegacyRouterCompat';
import useEachPbl from '@/hooks/useEachPbl';
import { useInfo } from '@/hooks/useInfo';
import Info from '@/types/info';
import { PblParams } from '@/utils/pbl';
import Checkbox from 'pastel/Checkbox';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';
import Modal from 'rui/pastel/Modal';

const today = dayjs().format('YYYYMMDD');

interface ButtonOffset {
  pos: [number, number];
  size: [number, number];
}
interface ButtonProps {
  url: string;
  label: string;
}
export interface ImageNoticePopupProps {
  notice_key: () => string;
  popup_type: PopupType;
  title: string;
  badge?: string;
  content?: React.ReactNode;
  image_url: string;
  start_date: string;
  end_date: string;
  link?: ButtonProps;
  links?: (ButtonProps & ButtonOffset)[];
  is_allowed?: (info: Info) => boolean;
}

export const ImageNoticePopup: React.FC<ImageNoticePopupProps> = ({
  notice_key,
  popup_type,
  title,
  badge,
  content,
  image_url,
  start_date,
  end_date,
  link,
  links,
  is_allowed = () => true,
}) => {
  const notice_key_value = notice_key();
  const { info } = useInfo();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(
    is_allowed(info) && localStorage.getItem(notice_key_value) !== today && dayjs().isBetween(start_date, end_date),
  );
  const [replayYn, setReplayYn] = useState<boolean>(false);
  const { targetRef: modalRef, isRevealed: isRevealedBanner } = useOnceReveal();
  const { eachPbl } = useEachPbl<PblParams>();
  useEffect(() => {
    if (isRevealedBanner) {
      eachPbl({
        navigation: 'main_modal',
        category: 'pageview',
      });
    }
  }, [isRevealedBanner]);

  const handleCheckboxClick = (checked: boolean) => {
    setReplayYn(checked);
    if (checked) {
      localStorage.setItem(notice_key_value, today);
    } else {
      localStorage.removeItem(notice_key_value);
    }
  };

  const handleClose = () => {
    setIsOpen(false);

    eachPbl({
      navigation: 'main_modal',
      category: 'click',
      object_id: 'call_to_action',
      object_type: 'button',
    });
  };

  const handlePopupButtonClick = (url: string) => {
    handleClose();

    if (url.includes('http://') || url.includes('https://')) {
      window.open(url, '_blank', 'noopener');
    } else {
      history.push(url);
    }
  };

  if (!is_allowed(info)) {
    return null;
  }

  return (
    <StyledModal isOpen={isOpen} width={520} onClose={handleClose}>
      <Section ref={modalRef}>
        <Image popup_type={popup_type} src={image_url} alt={title} />
        {link && <StyledFullButton onClick={() => handlePopupButtonClick(link.url)}>바로가기</StyledFullButton>}
        {links &&
          links.map((button) => (
            <StyledFloatingButton key={button.url} {...button} onClick={() => handlePopupButtonClick(button.url)}>
              {button.label}
            </StyledFloatingButton>
          ))}
      </Section>
      {popup_type === PopupType.CONTENT && (
        <Content>
          <VStack spacing={20}>
            <VStack spacing={10}>
              {badge && (
                <BaseText as='p' kind='Body_13_SemiBold' color={colors.zigzag_pink500}>
                  {badge}
                </BaseText>
              )}
              <BaseText as='p' kind='Heading_24_Bold'>
                {title}
              </BaseText>
            </VStack>
            <VStack spacing={30}>
              <BaseText kind='Body_14_Regular'>{content}</BaseText>
            </VStack>
            {link && (
              <StyledButton kind='primary' size='large' onClick={() => handlePopupButtonClick(link.url)}>
                {link.label} →
              </StyledButton>
            )}
          </VStack>
          <StyledDivider spacing={20} />
        </Content>
      )}
      <Footer>
        <Checkbox checked={replayYn} onChange={(e) => handleCheckboxClick(e.currentTarget.checked)}>
          <Replay>오늘 하루동안 이 창을 열지 않음</Replay>
        </Checkbox>
        <CloseButton kind='outlined_white' onClick={handleClose}>
          닫기
        </CloseButton>
      </Footer>
    </StyledModal>
  );
};

const StyledFloatingButton = styled.button<ButtonOffset>`
  position: absolute;
  ${({ pos, size }) => `left: ${pos[0]}px; top: ${pos[1]}px; width: ${size[0]}px; height: ${size[1]}px`};
  background: none;
  border: 0;
  font-size: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const StyledFullButton = styled(Button)`
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  font-size: 0;
  outline: none;
  cursor: pointer;
  &:hover {
    background: 0;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: transparent;
  }
`;

const Content = styled.div`
  padding: 30px;
  padding-bottom: 0px;
  background-color: #fff;
`;

const Section = styled.div`
  margin: 0;
  padding: 0;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  width: 520px;
  position: relative;
`;

const StyledButton = styled(Button)`
  width: 152px;
  ${text_styles.Body_13_Medium};
`;

const Image = styled.img<{ popup_type: PopupType }>`
  width: 100%;
  margin-bottom: -7px;
`;

const Footer = styled.div`
  background: white;
  padding: 10px 20px;
  border-radius: 0 0 6px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDivider = styled(Divider)`
  margin: 0px;
  margin-top: 30px;
`;

const CloseButton = styled(Button)`
  color: ${colors.gray800};
  border: none;
`;

const Replay = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray800};
`;
